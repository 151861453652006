a:focus,
button:focus,
input:focus {
	outline: 0!important
}

body {
	font-size: 1rem;
	font-family: Sen, sans-serif;
	font-weight: 400;
	line-height: 1.5;
	color: #121730
}

li,
ul {
	color: #121730
}

.y-inner_page ul {
	margin-bottom: 2rem;
	font-size: 1.2rem;
	line-height: 1.8em
}

.y-inner_page li {
	margin-bottom: .5em
}

p {
	margin-bottom: 2rem;
	font-size: 1.2rem;
	line-height: 1.8em
}

a {
	text-decoration: none!important;
	transition: all .3s ease-out;
	color: #3aa6f5
}

.container-fluid {
	max-width: 1600px;
	width: 100%;
}

a:hover {
	transition: all .3s ease-in
}
.img-responsive {
	max-width: 100%;
}
.thm-h h2 {
	font-family: Sen;
	letter-spacing: .03em;
	margin-bottom: .3em;
	text-transform: uppercase;
	font-weight: 700
}

.thm-h h4 {
	font-family: Sen;
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 200;
	letter-spacing: .1em;
	opacity: .6
}

.thm-h {
	margin-bottom: 50px
}

.thm-h-secondary {
	color: #3aa6f5
}

.sec-main-h {
	text-align: center;
	width: 100%
}

.arrow-down {
	transform: rotate(90deg)
}

.arrow-up {
	transform: rotate(-90deg)
}

.arrow-left {
	transform: rotate(-180deg)
}

#back-to-top {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 999;
	width: 45px;
	height: 45px;
	text-align: center;
	line-height: 42px;
	background: #fff;
	color: #dfdfdf;
	cursor: pointer;
	border: 0;
	border-radius: 0;
	text-decoration: none;
	transition: opacity .2s ease-out;
	opacity: 0;
	font-size: 32px;
	border: 1px solid #fff;
	box-shadow: 3px 2px 15px -4px rgba(0, 0, 0, .2)
}

#back-to-top:hover {
	background: #dfdfdf;
	color: #fff;
	transition: all .3s ease-out
}

#back-to-top.show {
	opacity: 1
}

.thm-form .field label {
	position: absolute;
	top: 17px;
	font-size: 16px;
	z-index: 99;
	left: 12px;
	transition: all .3s ease;
	color: #000
}

.thm-form .field {
	position: relative;
	margin-top: 30px;
	margin-bottom: 10px;
	height: 100%;
	background: #fff
}

.thm-form .field.active label {
	top: -20px;
	font-size: 12px;
	transition: all .3s ease;
	left: 0
}

.thm-form .field .form-control {
	background: 0 0;
	border: 0;
	font-size: 14px;
	resize: none;
	margin: 0;
	width: 100%;
	position: relative;
	z-index: 999;
	height: 100%
}

#prelouder {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999;
	width: 100%;
	height: 100%;
	background: transparent;
}

.sk-circle {
	margin: 40vh auto;
	width: 40px;
	height: 40px;
	position: relative
}

.sk-circle .sk-child {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0
}

.sk-circle .sk-child:before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: #3aa6f5;
	border-radius: 100%;
	-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	animation: sk-circleBounceDelay 1.2s infinite ease-in-out both
}

.sk-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg)
}

.sk-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg)
}

.sk-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg)
}

.sk-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg)
}

.sk-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg)
}

.sk-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg)
}

.sk-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg)
}

.sk-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg)
}

.sk-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg)
}

.sk-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg)
}

.sk-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg)
}

.sk-circle .sk-circle2:before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s
}

.sk-circle .sk-circle3:before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s
}

.sk-circle .sk-circle4:before {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}

.sk-circle .sk-circle5:before {
	-webkit-animation-delay: -.8s;
	animation-delay: -.8s
}

.sk-circle .sk-circle6:before {
	-webkit-animation-delay: -.7s;
	animation-delay: -.7s
}

.sk-circle .sk-circle7:before {
	-webkit-animation-delay: -.6s;
	animation-delay: -.6s
}

.sk-circle .sk-circle8:before {
	-webkit-animation-delay: -.5s;
	animation-delay: -.5s
}

.sk-circle .sk-circle9:before {
	-webkit-animation-delay: -.4s;
	animation-delay: -.4s
}

.sk-circle .sk-circle10:before {
	-webkit-animation-delay: -.3s;
	animation-delay: -.3s
}

.sk-circle .sk-circle11:before {
	-webkit-animation-delay: -.2s;
	animation-delay: -.2s
}

.sk-circle .sk-circle12:before {
	-webkit-animation-delay: -.1s;
	animation-delay: -.1s
}

@-webkit-keyframes sk-circleBounceDelay {
	0%,
	100%,
	80% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

@keyframes sk-circleBounceDelay {
	0%,
	100%,
	80% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

.navbar-toggler {
	font-size: 1.7rem;
	border: none
}

.form-control::-webkit-input-placeholder {
	opacity: 1
}

.form-control::-moz-placeholder {
	opacity: 1
}

.form-control:-ms-input-placeholder {
	opacity: 1
}

.form-control:-moz-placeholder {
	opacity: 1
}

.header-home-4 {
	height: 100vh;
	transition: all .5s ease-in;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	background-color: #06062a;
	transition: all .3s ease;
	position: relative;
	overflow: hidden
}

.header-home-4::after {
	content: "";
	display: block;
	background: rgba(7, 28, 78, .5);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0
}

.header-home-4>video {
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0
}

.header-home-4 h1,
.header-home-4 h5 {
	width: 100%;
	text-align: center;
	color: #fff;
	margin-bottom: 8vh
}

.header-home-4 h1 {
	font-family: Sen;
	font-size: 7vh;
	border-bottom: 1px solid #fff;
	padding-bottom: 5vh
}

.header-home-4 h5 {
	font-size: 2vh;
	color: #3aa6f5;
	text-transform: uppercase;
	font-weight: 100;
	letter-spacing: .3em
}

.header-home-1 {
	height: 100vh;
	transition: all .5s ease-in;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	background-color: #06062a;
	transition: all .3s ease;
	position: relative;
	overflow: hidden
}

.header-home-1::after {
	content: "";
	display: block;
	background: rgba(7, 28, 78, .5);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0
}

.header-home-1>video {
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0
}

.header-home-1 h1,
.header-home-1 h5 {
	width: 100%;
	text-align: center;
	color: #fff;
	margin-bottom: 8vh
}

.header-home-1 h1 {
	font-size: 7vh;
	padding-bottom: 0;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .05em
}

.header-home-1 h5 {
	font-size: 2vh;
	color: #fff;
	text-transform: uppercase;
	font-weight: 100;
	letter-spacing: .3em
}

.menu-1>div {
	transition: all .5s ease-in;
	border-bottom: 1px solid rgba(255, 255, 255, .3)
}

.menu-1.menu-1-scrolled>div {
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 60px);
	transition: all .5s ease-in
}

.hamburger {
	font: inherit;
	display: inline-block;
	overflow: visible;
	margin: 0;
	padding: 15px;
	cursor: pointer;
	transition-timing-function: linear;
	transition-duration: .15s;
	transition-property: opacity, filter;
	text-transform: none;
	color: inherit;
	border: 0;
	background-color: transparent
}

.hamburger.is-active:hover,
.hamburger:hover {
	opacity: .7
}

.hamburger-box {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px
}

.hamburger-inner::after {
	bottom: -10px
}

.hamburger-inner::after,
.hamburger-inner::before {
	display: block;
	content: ""
}

.hamburger--spring .hamburger-inner::before {
	top: 10px;
	transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
	position: absolute;
	width: 40px;
	height: 1px;
	transition-timing-function: ease;
	transition-duration: .15s;
	transition-property: transform;
	border-radius: 4px;
	background-color: #fff
}

.menu-1-scrolled .hamburger-inner,
.menu-1-scrolled .hamburger-inner::after,
.menu-1-scrolled .hamburger-inner::before {
	background-color: #00021d
}

.menu-1-scrolled .hamburger {
	padding-top: 12px;
	padding-bottom: 12px
}

.hamburger--spring .hamburger-inner::after {
	top: 20px;
	transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring.is-active .hamburger-inner::after {
	top: 0;
	transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
	transform: translate3d(0, 10px, 0) rotate(-45deg)
}

.hamburger--spring.is-active .hamburger-inner::before {
	top: 0;
	transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
	transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--spring.is-active .hamburger-inner {
	transition-delay: .22s;
	background-color: transparent!important
}

.menu-1 .nb-dark,
.menu-1 .nb-light {
	display: none;
	max-width: 80px;
	margin-right: 10px;
	transition: all .3s
}

.menu-1 .nb-light {
	display: inline-block
}

.menu-1.menu-1-scrolled .nb-dark {
	display: inline-block;
	max-width: 30px;
	transition: all .3s
}

.menu-1.menu-1-scrolled .nb-light {
	display: none
}

.menu-1.menu-1-scrolled .menu-1-inner {
	background-color: #fff;
	border-bottom: none;
	border-top: none;
	box-shadow: 0 10px 40px 0 rgba(47, 47, 47, .1);
	transition: all .3s ease
}

.menu-1.menu-1-scrolled .menu-1-inner .dropdown .btn,
.menu-1.menu-1-scrolled .menu-1-inner a:not(.dropdown-item) {
	color: #121730
}

.menu-1.menu-1-scrolled .toggle-dot,
.menu-1.menu-1-scrolled .toggle-dot:after,
.menu-1.menu-1-scrolled .toggle-dot:before {
	background: #121730
}

.menu-1.menu-1-scrolled .nav-search .searchclick .svg-search svg {
	fill: #121730
}

.menu-1.menu-1-scrolled .nav-search .searchclick {
	color: #121730
}

.menu-1.menu-1-scrolled .menu-1-inner .menu-1-logo .navbar-brand {
	background: 0 0
}

.menu-1.menu-1-scrolled .navbar-brand span.y {
	background: #06062a;
	color: #3aa6f5
}

.searchclick {

}

.menu-1-inner>div {
	border-top: none;
	border-left: none;
	border-right: none;
	margin-left: 0;
	text-align: center;
	padding: 0
}

.menu-1 .main_nav,
.menu-2 .main_nav {
	margin-top: 10px;
	tranisition: all .3s ease
}

.menu-1.menu-1-scrolled .main_nav {
	margin-top: 3px;
	tranisition: all .3s ease
}

.menu-1.menu-1-scrolled .menu-1-inner>div {
	background: 0 0
}

.menu-1.menu-1-scrolled .menu-1-inner .menu-1-logo {
	padding-left: 15px;
	tranisition: all .3s ease
}

.menu-1-inner .dropdown {
	display: inline-block;
	height: 100%;
	margin-left: 1.8%;
	margin-right: 1.8%;
	vertical-align: middle;
}

.menu-1-inner .dropdown .dropdown-item+.dropdown-item {
	border-top: 1px solid rgba(255, 255, 255, .4)
}

.menu-1-inner .dropdown.dropdown-a .dropdown-toggle:after {
	display: none
}

.menu-1-inner .dropdown-menu {
	background: #fff;
	margin-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	padding: 0;
	border: none
}

.menu-1-inner .dropdown-menu .dropdown-item {
	padding-top: 10px;
	padding-bottom: 10px
}

.menu-1-inner .dropdown .btn {
	height: auto;
	background: 0 0;
	border: none;
	border-radius: 0;
	font-size: 1rem;
	font-family: Sen;
	line-height: 1.3em;
	display: block;
	padding-top: 15px;
	padding-bottom: 29px;
	transition: all .3s ease
}

.menu-1.menu-1-scrolled .menu-1-inner .dropdown .btn {
	padding-top: 15px;
	padding-bottom: 15px;
	transition: all .3s ease
}

.dropdown a:active,
.dropdown a:focus,
.dropdown a:hover {
	background: #3aa6f5;
	color: #fff
}

.menu-1-inner .dropdown .btn:focus {
	box-shadow: none
}

.menu-1-inner ul.social {
	padding: 0;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	padding-bottom: 0;
	margin-bottom: 0;
	height: 100%
}

.menu-1-inner ul.social li {
	display: inline-block;
	vertical-align: middle;
	height: 100%
}

.menu-1-inner a:not(.dropdown-item) {
	font-family: Sen;
	width: auto;
	text-align: center;
	height: 100%;
	vertical-align: middle;
	padding-top: auto;
	color: #fff;
	display: inline-block;
	padding: .375rem .75rem
}

.menu-1-inner .btn-outline-success {
	color: #fff;
	padding: 0 10px 0
}

.menu-1-inner .menu-1-logo {
	border: none;
	background: 0 0;
	min-height: 60px;
	text-align: left;
	padding-left: 30px;
	tranisition: all .3s ease
}

.menu-1-inner a.navbar-brand {
	padding-top: 30px;
	padding-bottom: 30px;
	height: 100%;
	margin: auto;
	padding: 10px;
	width: auto;
	position: static;
	top: 0;
	left: 0;
	right: 0;
	bottom: auto;
	z-index: 99;
	text-align: left;
	border: none
}

.menu-1.menu-1-scrolled .navbar-brand {
	height: 100%;
	border-color: rgba(255, 255, 255, .5)
}

.menu-1 .navbar-toggler {
	width: auto;
	height: 100%
}

.toggle-dot {
	width: 3px;
	height: 3px;
	background: #fff;
	display: block;
	border-radius: 100%;
	margin: 7px auto;
	position: relative;
	transition: all 2s ease
}

.toggle-dot * {
	transition: all .2s ease
}

.toggle-dot:after,
.toggle-dot:before {
	content: "";
	display: block;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
	margin: auto;
	width: 3px;
	height: 3px;
	background: #fff;
	border-radius: 100%
}

.toggle-dot:after {
	right: 10px;
	transition: all .4s ease
}

.toggle-dot:before {
	left: 10px;
	transition: all .4s ease
}

.navbar-toggler:hover .toggle-dot:before {
	left: -12px;
	transition: all .4s ease
}

.navbar-toggler:hover .toggle-dot:after {
	right: -12px;
	transition: all .4s ease
}

.main_nav .dropdown:hover .dropdown-menu {
	display: block;
	-webkit-animation-name: menuanimation;
	-webkit-animation-duration: .3s;
	animation-name: menuanimation;
	animation-duration: .3s
}

.sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 999;
	top: 0;
	right: -30px;
	background-color: #06062a;
	background-image: linear-gradient(45deg, #101920, #18456c 80%);
	overflow-x: hidden;
	transition: .5s;
	padding-top: 75px;
	box-shadow: 0 0 25px 8px rgba(44, 71, 74, .4)
}

.sidenav a {
	padding: 8px 15px 8px 15px;
	text-decoration: none;
	color: #fff;
	display: block;
	transition: .3s
}

text-sha .sidenav .dropdown a {
	background: 0 0!important;
	text-shadow: 1px 2px 1px rgba(0, 0, 0, .3);
	display: inline-block;
	width: 100%
}

.sidenav .dropdown-menu {
	padding-top: 0;
	padding-bottom: 0;
	background: rgba(255, 255, 255, .1);
	border-radius: 0;
	float: none;
}

.sidenav .dropdown {
	position: relative;
	float: none;

}

.sidenav .dropdown-toggle {
	position: static
}

.sidenav .dropdown-toggle::after {
	position: absolute;
	right: 30px;
	top: 15px
}

.sidenav .dropdown .dropdown-item {
	padding-left: 24px
}

.sidenav a:hover {
	color: #f1f1f1
}

.sidenav .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px
}

.sidenav[style="width: 330px;"] .closebtn {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	margin: 0;
	text-align: right;
	padding-right: 30px;
	z-index: 99;
}

.sidenav[style="width: 330px;"] > nav > div {
	position: relative;
	z-index: 99;
}

.sidenav ul {
	padding: 0;
	width: 300px
}

.sidenav .dropdown .dropdown-menu {
	position: static!important;
	top: 0;
	left: 0;
	transform: none!important;
	width: 100%;
	margin-bottom: 10px
}

.sidenav ul.tagnav {
	padding: 0 15px
}

.tagnav li {
	display: inline-block;
	width: auto;
	margin: 2%
}

.tagnav li a {
	display: inline-block;
	text-align: center;
	font-weight: 700;
	padding: 10px 15px;
	background: rgba(255, 255, 255, .2);
	width: 100%;
	font-size: 14px;
	border-radius: 3px
}

.tagnav li a:hover {
	background: #121730
}

.sidenav .nav-item {
	border-bottom: 1px solid rgba(255, 255, 255, .1)
}

.sidenav .nav-item a {
	padding-left: 20px;
	font-size: 14px;
	font-weight: 600;
	opacity: .5
}

.sidenav .dropdown a:active,
.sidenav .dropdown a:focus,
.sidenav .dropdown a:hover,
.sidenav .nav-item a:hover {
	color: #fff;
	opacity: 1
}

.sidenav_cat {
	margin-bottom: 30px;
	color: rgba(255, 255, 255, .5)
}

.sidenav_cat li {
	list-style: none
}

.sidenav_cat_inner {
	padding-left: 20px
}

.sc_first.sidenav_cat {
	border-top: 1px solid rgba(255, 255, 255, .2);
	padding-top: 30px
}

.sidenav_cat h3 {
	color: #fff;
	font-weight: 700;
	font-size: 18px;
	padding-left: 20px
}

.menu-1 .navbar-nav .nav-link {
	color: #fff
}

.menu-1 .navbar-nav .nav-item {
	width: 100%;
	border-top: none
}

.menu-1.nav-open .menu-1-inner .navbar-brand {
	height: 100%;
	border-color: rgba(255, 255, 255, .6)
}

.menu-1 .navbar {
	width: 100%;
	padding: 0
}

.menu-1 .navbar-nav .nav-link {
	border-bottom: 1px solid rgba(255, 255, 255, .6);
	border-top: none;
	position: relative
}

.menu-1 .navbar-nav .nav-link:before {
	content: "|";
	position: absolute;
	right: 0;
	color: rgba(255, 255, 255, .4)
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none;
	background: rgba(255, 255, 255, .8)
}

.navbar-nav .dropdown-menu .dropdown-item {
	position: relative;
	padding: .75rem 1rem .75rem 2rem
}

.navbar-nav .dropdown-menu .dropdown-item:before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background: rgba(0, 0, 0, .4);
	position: absolute;
	left: .75rem;
	top: 0
}

.navbar-nav .dropdown-menu .dropdown-item:after {
	content: "";
	display: block;
	width: .5rem;
	height: 1px;
	background: rgba(0, 0, 0, .4);
	position: absolute;
	left: .75rem;
	top: 0;
	bottom: 0;
	margin: auto
}

.navbar-nav .dropdown-menu a:last-child.dropdown-item:before {
	height: 50%
}

.header-3 .menu-2-inner>div {
	background: #fff
}

.header-3 .menu-2-inner .btn-primary,
.header-3 .menu-2-inner a:not(.dropdown-item),
.header-3 .nav-search .searchclick {
	color: #06062a
}

.header-3 .nav-search .searchclick .svg-search svg {
	fill: #06062a
}

.header-3 .toggle-dot,
.header-3 .toggle-dot::after,
.header-3 .toggle-dot::before {
	background: #06062a
}

.header-3 .menu-2-inner .menu-2-logo .navbar-brand {
	background: #fff
}

.header-3 .navbar-brand span.y {
	background: #06062a;
	color: #fff
}

.hh3 {
	background-color: rgba(0, 16, 40, .6);
	min-height: calc(100vh - 98px);
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-blend-mode: multiply;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.hh3 .header-cont h1,
.hh3 .header-cont h5 {
	width: 100%;
	text-align: center;
	color: #fff;
	margin-bottom: 7vh
}

.hh3 .header-cont h1 {
	font-family: Sen;
	font-weight: 700;
	font-size: 8vh
}

.hh3 .header-cont h1 span {
	color: #a87e0a;
	text-transform: capitalize
}

.hh3 .header-cont h5 {
	color: rgba(255, 255, 255, .4)
}

.header-home-3-outer {
	position: relative;
	z-index: 99
}

.header-home-3-outer .compass .compass_inner>div {
	color: #a3832b
}

.header-home-3-outer .compass .navigation_a a {
	color: #a3832b
}

.header-home-3-outer .compass .compass_inner::after {
	background: #a87e0a
}

.header-home-3-outer .compass .navigation_a a::after {
	background-image: url('assets/images/down-3.png')
}

.header-home-3.owl-carousel .owl-stage-outer {
	padding-bottom: 0
}

.header-home-3.owl-carousel .owl-dots {
	position: absolute;
	bottom: 30px
}

.nav-search .nav-search-form.search-open {
	display: flex;
	width: 36%
}

.header-cont {
	z-index: 999
}

.banner-last {
	height: 30%;
	position: absolute;
	bottom: 0;
	width: 100%;
	top: auto!important;
	z-index: 9999;
	box-shadow: 0 -9px 8px -9px rgba(0, 0, 0, .1);
	background: #fff
}

.banner-last-boat {
	width: 30%;
	position: absolute;
	right: 15%!important;
	left: auto!important;
	bottom: 27%;
	top: auto!important;
	z-index: 999
}

.boat img {
	max-width: 100%
}

.header-3 .compass .compass_inner::after {
	background: #06062a
}

.header-3 .compass .compass_inner>div {
	color: #06062a
}

.header-3 .compass .navigation_a a {
	color: #06062a
}

.header-3 .compass .navigation_a a::after {
	background-image: url('assets/images/down-dark.png')
}

.header-3 .menu-2-scrolled .menu-2-inner>div {
	border-bottom-color: #dfdfdf
}

.header-3 .menu-2-scrolled .menu-2-inner .menu-2-logo {
	border-bottom: 1px solid #dfdfdf
}

.header-home-2 {
	background-image: url('/assets/images/kopaonik_bg.jpg');
	background-attachment: fixed;
	background-size: cover;
	background-position: center bottom;
	height: 100vh;
	transition: all .5s ease-in;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding-left: 45px
}

.header-cont-2 {
	z-index: 999
}

.header-home-2 .header-cont {
	z-index: 999;
	max-width: 42%;
	margin-left: 45px;
	margin-right: auto
}

.header-home-2 h1,
.header-home-2 h5 {
	width: 100%;
	text-align: left;
	color: #fff;
	margin-bottom: 24px;
}

.header-home-2 h1 {
	font-weight: 700;
	font-size: 60px;
	text-shadow: 11px 2px 3px rgba(0, 0, 0, .3)
}

.header-home-2 h5 {
	font-size: 4vh;
	color: #022431;
	max-width: 425px;
	font-weight: 800
}

.navbar-dark {
	background: 0 0!important
}

.navbar {
	transition: all .3s ease .5s
}

.navbar.navbar-scrolled {
	transition: all .3s ease 0s
}

.navbar.navbar-scrolled .nav-item .nav-link {
	background-color: #06062a
}

.navbar-toggler {
	width: 56px;
	height: 56px;
	text-align: center
}

.navbar-toggler * {
	transition: all .2s ease-in
}

.navbar-toggler .toggle-line {
	height: 1px;
	width: 100%;
	background: #fff;
	display: block;
	margin: 9px 0;
	transform: none;
	opacity: 1
}

.navbar-toggler.collapsed .toggle-line {
	height: 1px;
	width: 100%;
	background: #fff;
	display: block;
	margin: 9px auto;
	transform: none;
	opacity: 1
}

.navbar-toggler .tl-3 {
	transform: rotate(45deg);
	margin: -1px auto;
	height: 2px
}

.navbar-toggler .tl-2 {
	margin: 0;
	opacity: 0
}

.navbar-toggler .tl-1 {
	transform: rotate(-45deg);
	margin: -1px auto;
	height: 2px
}

.navbar-toggler:hover .tl-1,
.navbar-toggler:hover .tl-3 {
	width: 60%
}

.navbar-nav {
	background: #06062a;
	padding: 15px;
	text-align: center;
	height: calc(100vh - 100px);
	overflow-y: auto
}

.navbar-nav::-webkit-scrollbar {
	width: 8px
}

.navbar-nav::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px
}

.navbar-nav::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, .4);
	border-radius: 10px
}

.navbar-nav::-webkit-scrollbar-thumb:hover {
	background: rgba(255, 255, 255, .7)
}

.navbar-brand {
	border: 2px solid #fff;
	padding: 0 12px 0 0
}

.navbar-brand img {
	max-height: 55px
}

.navbar-brand span.y {
	background: #fff;
	margin-top: 0;
	display: inline-block;
	color: red;
	padding: 6px 12px;
	margin-right: 10px;
	color: #06062a;
	font-weight: 500;
	text-transform: uppercase
}

.navbar-brand span.sailing {
	margin-top: -7px
}

.navbar-brand span.sailing b {
	margin-top: -2px;
	display: block;
	color: #3aa6f5;
	padding: 0 6px 0;
	margin-right: 0;
	font-weight: 1000;
	text-transform: uppercase;
	font-size: .5em;
	text-align: center
}

.col-toggler-2 {
	display: inline-block;
	vertical-align: middle;
	padding-right: 15px
}

.menu-1-inner>div.nav-search {
	position: static;
	text-align: right;
	vertical-align: middle
}

.nav-search .login_but {
	display: inline-block;
	padding: 19px 15px 19px 0;
	position: relative;
}

.login_but:hover .cart_index {
	display: block;
	-webkit-animation-name: menuanimation;
	-webkit-animation-duration: .3s;
	animation-name: menuanimation;
	animation-duration: .3s
}

.cart_index {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background: #171d33;
	background-image: linear-gradient(45deg, #205481, #18456c 80%);
	padding: 10px;
	width: 300px;
	color: rgba(255, 255, 255, .9);
	left: -110px;
	text-align: left;
	z-index: 9999;
}

.cart_index h5 {
	margin-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, .3);
	padding-bottom: 10px
}

.nav-search .cart_index h5 a {
	padding: 0;
	color: #fff!important
}

.cart_totals {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.cart_totals .checkout_btn {
	border: 1px solid #fff;
	margin-right: auto;
	color: #fff!important
}

.cart_index ul {
	padding: 0
}

.cart_index li {
	list-style: none;
	text-align: left;
	color: rgba(255, 255, 255, .9);
	padding: 7px 0;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.cart_index li .cart_remove {
	opacity: 0;
	width: 0;
	transition: all 0;
	cursor: pointer;
	position: absolute
}

.cart_index li:hover .cart_remove {
	opacity: 1;
	width: 30px;
	transition: all .3s;
	position: relative
}

.cart-price-amount {
	font-weight: 700;
	margin-left: auto;
	margin-right: 15px
}

.cart_totals {
	text-align: right
}

.nav-search .nav-search-form {
	position: absolute;
	top: 100%;
	z-index: 99;
	right: 45px;
	right: 32px;
	display: none
}

@-webkit-keyframes menuanimation {
	0% {
		transform: translateY(-10%);
		opacity: 0
	}
	100% {
		transform: translateY(0);
		opacity: 1
	}
}

@keyframes menuanimation {
	0% {
		transform: translateY(-10%);
		opacity: 0
	}
	100% {
		transform: translateY(0);
		opacity: 1
	}
}

.nav-search .nav-search-form.search-open {
	display: flex;
	width: 36%;
	transition: all .3s ease-in .6s;
	-webkit-animation-name: menuanimation;
	-webkit-animation-duration: .3s;
	animation-name: menuanimation;
	animation-duration: .3s;
	padding: 25px;
	background: #171d33;
	background-image: linear-gradient(45deg, #205481, #18456c 80%)
}

.nav-search .nav-search-form input {
	background: 0 0;
	border-radius: 0;
	border-color: rgba(255, 255, 255, .6);
	position: relative;
	color: #fff;
	padding-top: 11px;
	height: auto;
	padding-bottom: 11px
}

.nav-search .nav-search-form .form-control::-webkit-input-placeholder {
	color: #fff
}

.nav-search .nav-search-form .form-control::-moz-placeholder {
	color: #fff
}

.nav-search .nav-search-form .form-control:-ms-input-placeholder {
	color: #fff
}

.nav-search .nav-search-form .form-control:-moz-placeholder {
	color: #fff
}

.nav-search .nav-search-form .btn {
	border-color: rgba(255, 255, 255, .6);
	border-radius: 0;
	background: 0 0;
	border-left: 0
}

.nav-search .nav-search-form .btn:focus,
.nav-search .nav-search-form input:focus {
	box-shadow: none;
	border-color: #fff;
	border: 1px solid #fff;
	margin-top: -1px
}

.nav-search .searchclick {
	background: 0 0;
	border: none;
	cursor: pointer;
	height: 100%;
	width: auto;
	color: #fff;
	padding-top: 5px;
	vertical-align: middle
}

.nav-search .searchclick .svg-search svg {
	fill: #fff;
	width: 25px;
	display: block;
	transition: all .3s ease-in;
	-webkit-animation-name: btn-ani;
	-webkit-animation-duration: .3s;
	animation-name: btn-ani;
	animation-duration: .3s
}

.nav-search .searchclick .fa-times {
	transition: all .3s ease-in .6s;
	-webkit-animation-name: btn-ani;
	-webkit-animation-duration: .3s;
	animation-name: btn-ani;
	animation-duration: .3s
}

.nav-search .searchclick svg {
	display: none
}

@-webkit-keyframes btn-ani {
	0% {
		transform: translateX(-50%);
		opacity: 0
	}
	100% {
		transform: translateX(0);
		opacity: 1
	}
}

@keyframes btn-ani {
	0% {
		transform: translateX(-50%);
		opacity: 0
	}
	100% {
		transform: translateX(0);
		opacity: 1
	}
}

.menu-2-scrolled .nav-search-form {
	background: #1b1d2f
}

.compass {
	height: 125px;
	position: absolute;
	bottom: 12px;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center
}

.compass .compass_innera {
	width: 60px;
	height: 60px;
	margin: auto;
	position: relative;
	border-radius: 50%;
	z-index: 99;
	background: 0 0;
	left: -.5px
}

.compass .compass_inner {
	width: 50px;
	height: 50px;
	margin: auto;
	position: relative;
	border-radius: 50%;
	background: 0 0
}

.compass .compass_inner>div {
	font-size: 11px;
	font-weight: 500;
	color: #fff;
	font-family: "Times New Roman"
}

.compass .compass_inner .north {
	display: block;
	position: absolute;
	top: -12px;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center
}

.compass .compass_inner .north::after {
	content: "sn";
	position: absolute;
	z-index: 1;
	display: block;
	left: -4px;
	top: 12px;
	font-size: 10px;
	transform: rotate(-45deg)
}

.compass .compass_inner .east {
	position: absolute;
	bottom: -13px;
	right: 0;
	left: 0;
	text-align: center
}

.compass .compass_inner .east::after {
	content: "ew";
	position: absolute;
	z-index: 1;
	display: block;
	right: 2px;
	bottom: 7px;
	font-size: 10px;
	transform: rotate(-45deg)
}

.compass .compass_inner .south {
	position: absolute;
	height: 100%;
	vertical-align: middle;
	left: -7px;
	padding-top: 23px
}

.compass .compass_inner .south::after {
	content: "se";
	position: absolute;
	z-index: 1;
	display: block;
	left: 11px;
	bottom: -2px;
	font-size: 10px;
	transform: rotate(45deg)
}

.compass .compass_inner .west {
	position: absolute;
	height: 100%;
	right: -11px;
	text-align: center;
	padding-top: 23px
}

.compass .compass_inner .west::after {
	content: "sn";
	position: absolute;
	z-index: 1;
	display: block;
	right: 6px;
	top: 0;
	font-size: 10px;
	transform: rotate(45deg)
}

.compass .compass_inner::after {
	content: "";
	position: absolute;
	z-index: 1;
	display: block;
	width: 8px;
	height: 8px;
	background: #fff;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	border-radius: 50%
}

.compass .navigation_a {
	position: relative
}

.compass .navigation_a a {
	color: #fff;
	height: 50px;
	display: inline-block;
	margin-top: 15px;
	font-size: 13px;
	font-style: italic;
	position: relative;
	z-index: 9999;
	letter-spacing: .2em
}

.compass .navigation_a a p {
	font-size: 14px;
	line-height: 28px;
	letter-spacing: .5px;
	display: block;
	font-style: italic;
	color: #fff;
	padding: 15px 0 0;
	font-family: Sen
}

.compass .navigation_a a::after {
	content: "";
	display: block;
	width: 24px;
	height: 30px;
	position: absolute;
	bottom: -13px;
	z-index: 99999;
	left: 0;
	right: 0;
	margin: auto;
	background-image: url('assets/images/down.png');
	background-size: 100%;
	background-repeat: no-repeat
}

.compass_innera {
	transform-origin: center;
	animation: naviani .4s ease-in-out infinite
}

@-webkit-keyframes naviani {
	0%,
	100% {
		transform: rotate(15deg)
	}
	50% {
		transform: rotate(5deg)
	}
}

@keyframes naviani {
	0%,
	100% {
		transform: rotate(15deg)
	}
	50% {
		transform: rotate(5deg)
	}
}

.menu-2>div {
	transition: all .5s ease-in;
	background: rgba(1, 20, 104, 0.5)
}

.menu-2.menu-2-scrolled>div {
	background: rgba(1, 20, 104, 1);
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 60px);
	transition: all .5s ease-in
}

.menu-2.menu-2-scrolled .menu-2-inner {
	background-color: #fff;
	border-bottom: none;
	border-top: none;
	box-shadow: 0 10px 40px 0 rgba(47, 47, 47, .1)
}

.menu-2.menu-2-scrolled .menu-2-inner .dropdown .btn,
.menu-2.menu-2-scrolled .menu-2-inner a:not(.dropdown-item) {
	color: #121730
}

.menu-2.menu-2-scrolled .toggle-dot,
.menu-2.menu-2-scrolled .toggle-dot:after,
.menu-2.menu-2-scrolled .toggle-dot:before {
	background: #121730
}

.menu-2.menu-2-scrolled .menu-2-inner .menu-2-logo .navbar-brand {
	background: 0 0
}

.menu-2.menu-2-scrolled .navbar-brand span.y {
	background: #06062a;
	color: #3aa6f5
}

.menu-2-inner>div {
	border: 1px solid rgba(255, 255, 255, .6);
	border-top: none;
	border-left: none;
	border-right: none;
	margin-left: 0;
	text-align: center;
	background-color: rgba(0, 0, 0, .2);
	padding: 0
}

.menu-2.menu-2-scrolled .menu-2-inner>div {
	background: 0 0
}

.menu-2-inner .dropdown {
	height: 100%
}

.menu-2-inner .dropdown .btn {
	height: 100%;
	background: 0 0;
	border: none;
	border-radius: 0;
	font-size: 1rem;
	font-family: Sen
}

.dropdown a:active,
.dropdown a:focus,
.dropdown a:hover {
	background: #bfdadd;
	color: #36607b
}

.menu-2-inner .dropdown .btn:focus {
	box-shadow: none
}

.menu-2-inner ul.social {
	padding: 0;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	padding-bottom: 0;
	margin-bottom: 0;
	height: 100%
}

.menu-2-inner ul.social li {
	display: inline-block;
	vertical-align: middle;
	height: 100%
}

.menu-2-inner a:not(.dropdown-item) {
	font-family: Sen;
	width: 100%;
	text-align: center;
	height: 100%;
	vertical-align: middle;
	padding-top: auto;
	color: #fff;
	display: block;
	padding: 12px 10px 0
}

.menu-2-inner a:hover {
	background: #3aa6f5;
	color: #fff
}

.menu-2-inner .fa-search {
	color: #fff;
	padding: 0 10px 0
}

.menu-2-inner .menu-2-logo {
	border: none;
	background: 0 0
}

.menu-2-inner .menu-2-logo .navbar-brand {
	background: rgba(0, 0, 0, .2)
}

.menu-2-inner a.navbar-brand {
	padding-top: 0;
	padding-bottom: 0;
	height: 200%;
	margin: auto;
	padding: 0;
	width: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: auto;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #fff;
	border-top-color: rgba(255, 255, 255, .5);
	border-top: none
}

.menu-2.menu-2-scrolled .navbar-brand {
	height: 100%;
	border-color: rgba(255, 255, 255, .5)
}

.menu-2 .navbar-toggler {
	width: 100%;
	height: auto
}

.toggle-dot {
	width: 3px;
	height: 3px;
	background: #fff;
	display: block;
	border-radius: 100%;
	margin: 7px auto;
	position: relative;
	transition: all 2s ease
}

.toggle-dot * {
	transition: all .2s ease
}

.toggle-dot:after,
.toggle-dot:before {
	content: "";
	display: block;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
	margin: auto;
	width: 3px;
	height: 3px;
	background: #fff;
	border-radius: 100%
}

.toggle-dot:after {
	right: 10px;
	transition: all .4s ease
}

.toggle-dot:before {
	left: 10px;
	transition: all .4s ease
}

.navbar-toggler:hover .toggle-dot:before {
	left: -12px;
	transition: all .4s ease
}

.navbar-toggler:hover .toggle-dot:after {
	right: -12px;
	transition: all .4s ease
}

.menu-2 .navbar-nav {
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: space-between;
	height: auto;
	background: 0 0;
	padding: 0
}

.menu-2 .navbar-nav .nav-link {
	color: #fff
}

.menu-2 .navbar-nav .nav-item {
	width: 100%;
	border-top: none
}

.menu-2 .navbar {
	width: 100%;
	padding: 0
}

.menu-2 .navbar-nav .nav-link {
	border-bottom: 1px solid rgba(255, 255, 255, .6);
	border-top: none;
	position: relative
}

.menu-2 .navbar-nav .nav-link:before {
	content: "|";
	position: absolute;
	right: 0;
	color: rgba(255, 255, 255, .4)
}

.navbar-nav .dropdown-menu {
	position: static;
	float: none;
	background: rgba(255, 255, 255, .8)
}

.navbar-nav .dropdown-menu .dropdown-item {
	position: relative;
	padding: .75rem 1rem .75rem 2rem
}

.navbar-nav .dropdown-menu .dropdown-item:before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background: rgba(0, 0, 0, .4);
	position: absolute;
	left: .75rem;
	top: 0
}

.navbar-nav .dropdown-menu .dropdown-item:after {
	content: "";
	display: block;
	width: .5rem;
	height: 1px;
	background: rgba(0, 0, 0, .4);
	position: absolute;
	left: .75rem;
	top: 0;
	bottom: 0;
	margin: auto
}

.navbar-nav .dropdown-menu a:last-child.dropdown-item:before {
	height: 50%
}

.section-menu .main-section-menu {
	display: flex;
	text-align: center;
	padding: 0;
	margin: 0 15px;
	position: relative
}

.main-section-menu:before {
	content: "";
	display: block;
	width: 1px;
	height: 100px;
	position: absolute;
	bottom: 100%;
	top: auto;
	left: 0;
	right: 0;
	margin: auto;
	background: #dfdfdf;
	display: none
}

.section-menu .main-section-menu li {
	list-style: none;
	width: 25%
}

.main-section-menu li a {
	padding-top: 2.5rem;
	display: block;
	padding-bottom: 2.5rem;
	width: 100%;
	height: 100%;
	color: #000;
	font-size: 1.5rem;
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	font-family: Sen
}

.main-section-menu li a span {
	display: block;
	font-size: .8rem
}

.main-section-menu li a:hover {
	background: #06062a;
	color: #fff;
	border-color: #06062a;
	transition: all .2s ease-in
}

.main-section-menu li a img {
	position: relative;
	top: -10px;
	display: block;
	opacity: 0;
	transition: all .2s ease .3s;
	margin-top: 10px;
	height: auto;
	display: block;
	margin: auto
}

.main-section-menu li a:hover img {
	top: 10px;
	opacity: 1;
	transition: all .3s ease-in .2s;
	color: #3aa6f5;
	height: auto
}

#h-section-menu {
	padding-top: 3vh;
	padding-bottom: 3vh
}

.section-menu.secmenu-type-1 .main-section-menu li {
	background-size: cover;
	background-position: center center;
	position: relative;
	border-left: 1px solid #fff;
	border-top: 15px solid #fff;
	background-color: #22caef;
	background-blend-mode: multiply
}

.section-menu.secmenu-type-1 .main-section-menu li:first-child {
	border-left: none
}

.section-menu.secmenu-type-1 .main-section-menu li:after {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	background: rgba(7, 28, 78, .5);
	position: absolute;
	z-index: 9;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0
}

.section-menu.secmenu-type-1 .main-section-menu li a {
	position: relative;
	z-index: 99;
	color: #fff;
	display: flex;
	align-items: center;
	height: 100%;
	transition: all .3s ease-in .3s;
	flex-wrap: wrap
}

.section-menu.secmenu-type-1 .main-section-menu li a div,
.section-menu.secmenu-type-1 .main-section-menu li a h5,
.section-menu.secmenu-type-1 .main-section-menu li a span {
	width: 100%
}

.section-menu.secmenu-type-1 li a:hover {
	background: 0 0
}

.section-menu.secmenu-type-1 .main-section-menu li a h5 {
	top: 40px;
	transition: all .5s ease;
	position: relative
}

.section-menu.secmenu-type-1 .main-section-menu li:hover a h5 {
	transition: all .5s ease;
	top: 0
}

.section-menu.secmenu-type-1 li a span {
	display: block;
	font-size: 1rem;
	max-width: calc(100% - 30px);
	margin: auto;
	font-weight: 500;
	visibility: hidden;
	opacity: 0;
	transition: all .5s ease
}

.section-menu.secmenu-type-1 li:hover a span {
	visibility: visible;
	opacity: 1;
	transition: all .5s ease
}

.home-about {
	margin: 130px auto 0;
	max-width: 800px
}

.home-about p {
	font-size: 1.4rem;
	text-align: center;
	line-height: 2em;
	font-family: Sen
}

.btn-wrap-center {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3rem
}

.button-fancy {
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	display: inline-block;
	padding: 16px 42px;
	text-decoration: none;
	position: relative;
	letter-spacing: .1em
}

.read-more.button-fancy {
	display: table;
	clear: both;
	margin-top: 20px
}

.button-fancy>.btn-arrow {
	background-color: #05c1eb;
	background-image: linear-gradient(45deg, #3aa6f5, #21cff6);
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	right: 50%;
	border-style: solid;
	border-width: 1px;
	width: 100%;
	height: 100%;
	z-index: 50;
	will-transform: translate;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	-webkit-transition: right .5s ease-in-out, width .4s ease .3s, height .3s ease .3s, border-width .4s ease-in-out, background-color .2s ease-in, border-color .4s ease-in-out, -webkit-transform .5s ease-in;
	transition: right .5s ease-in-out, width .4s ease .3s, height .3s ease .3s, border-width .4s ease-in-out, background-color .2s ease-in, border-color .4s ease-in-out, -webkit-transform .5s ease-in;
	transition: transform .5s ease-in, right .5s ease-in-out, width .4s ease .3s, height .3s ease .3s, border-width .4s ease-in-out, background-color .2s ease-in, border-color .4s ease-in-out;
	transition: transform .5s ease-in, right .5s ease-in-out, width .4s ease .3s, height .3s ease .3s, border-width .4s ease-in-out, background-color .2s ease-in, border-color .4s ease-in-out, -webkit-transform .5s ease-in
}

.button-fancy>.btn-arrow:after,
.button-fancy>.btn-arrow:before {
	background-color: transparent;
	content: '';
	display: block;
	height: 2px;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transition: all .2s;
	transition: all .2s;
	-webkit-transition-delay: 0;
	transition-delay: 0
}

.button-fancy>.btn-arrow:before {
	-webkit-transform-origin: top right;
	transform-origin: top right
}

.button-fancy>.btn-arrow:after {
	-webkit-transform-origin: bottom right;
	transform-origin: bottom right
}

.button-fancy:hover.-white>.btn-arrow {
	background-color: #fff
}

.button-fancy:hover.-white>.btn-arrow:after,
.button-fancy:hover.-white>.btn-arrow:before {
	background-color: #fff
}

.button-fancy:hover.-blue>.btn-arrow {
	background-color: #06062a
}

.button-fancy:hover.-blue>.btn-arrow:after,
.button-fancy:hover.-blue>.btn-arrow:before {
	background-color: #06062a
}

.button-fancy:hover.-color-1>.btn-arrow {
	background-color: #3aa6f5
}

.button-fancy:hover.-color-1>.btn-arrow:after,
.button-fancy:hover.-color-1>.btn-arrow:before {
	background-color: #3aa6f5
}

.button-fancy:hover>.btn-arrow {
	height: 2px;
	border-width: 0;
	border-color: transparent;
	width: 56px;
	right: 0;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	-webkit-transition: right .5s ease .2s, width .4s ease, height .3s ease-in-out, border-width .3s ease-in-out, background-color .2s ease-in .3s, border-color .3s ease-in-out, -webkit-transform .5s ease-in;
	transition: right .5s ease .2s, width .4s ease, height .3s ease-in-out, border-width .3s ease-in-out, background-color .2s ease-in .3s, border-color .3s ease-in-out, -webkit-transform .5s ease-in;
	transition: transform .5s ease-in, right .5s ease .2s, width .4s ease, height .3s ease-in-out, border-width .3s ease-in-out, background-color .2s ease-in .3s, border-color .3s ease-in-out;
	transition: transform .5s ease-in, right .5s ease .2s, width .4s ease, height .3s ease-in-out, border-width .3s ease-in-out, background-color .2s ease-in .3s, border-color .3s ease-in-out, -webkit-transform .5s ease-in
}

.button-fancy:hover>.btn-arrow:after,
.button-fancy:hover>.btn-arrow:before {
	width: 8px;
	-webkit-transition-delay: .4s;
	transition-delay: .4s
}

.button-fancy:hover>.btn-arrow:before {
	-webkit-transform: rotate(40deg);
	transform: rotate(40deg)
}

.button-fancy:hover>.btn-arrow:after {
	-webkit-transform: rotate(-40deg);
	transform: rotate(-40deg)
}

.button-fancy:hover>.text {
	-webkit-transform: translateX(-42px);
	transform: translateX(-42px);
	-webkit-transition: -webkit-transform .4s ease-in-out .2s;
	transition: -webkit-transform .4s ease-in-out .2s;
	transition: transform .4s ease-in-out .2s;
	transition: transform .4s ease-in-out .2s, -webkit-transform .4s ease-in-out .2s;
	color: #3aa6f5
}

.button-fancy>.text {
	color: #fff
}

.button-fancy.-white {
	color: #fff;
	border-color: #fff
}

.button-fancy.-blue {
	color: #32325d;
	border-color: #32325d
}

.button-fancy.-color-1 {
	border-color: #3aa6f5
}

.button-fancy.-color-1:hover {
	color: #3aa6f5
}

.button-fancy>.text {
	display: block;
	position: relative;
	z-index: 100;
	-webkit-transition: -webkit-transform .3s ease-in-out;
	transition: -webkit-transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

.btn-round {
	border-radius: 100px
}

.main-section {
	padding-top: 75px;
	padding-bottom: 75px;
	position: relative
}

.main-section.no-a-b:after,
.main-section.no-a-b:before {
	display: none
}

.main-section.dark {
	background: #06062a;
	color: #fff
}

.dark li,
.dark ul {
	color: #fff
}

.main-section .rtl-sec {
	flex-direction: row-reverse
}

.half-section .writing-sec {
	height: 100%;
	padding: 15px
}

.half-section .writing-sec p {
	margin-bottom: 3.5rem
}

.half-left .img-sec {
	margin-right: -15px;
	overflow: hidden
}

.half-right .img-sec {
	margin-left: -15px;
	overflow: hidden
}

.img-sec img {
	transition: all .6s ease
}

.main-section:hover .img-sec img {
	transform: scale(1.1);
	transition: all 5s ease
}

.main-section.managment-sec {
	background-size: cover;
	background-position: center center
}

.h-our-history {
	background-size: cover;
	background-position: center left;
	margin-top: 0;
	margin-bottom: 0
}

.main-section.h-our-history::before {
	display: none
}

.main-section.h-our-history::after {
	top: 100%;
	bottom: auto
}

.bg-gray {
	background-color: #ebebeb
}

.h-our-history-2 .half-left {
	position: relative
}

.h-our-history-2 .half-left::before {
	content: "";
	display: block;
	position: absolute;
	width: 300px;
	background: #fff;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100%;
	z-index: 1;
	opacity: .5;
	height: 300px;
	border-radius: 100%;
	bottom: 0
}

.h-our-history-2 .half-left {
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center
}

.h-our-history-2 .half-left img {
	position: relative;
	z-index: 99;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto
}

.instagram-sec,
.instagram-sec.dark {
	background: #0e1756;
	background-image: linear-gradient(0deg, #0e1756 50%, #fff 40%)
}

.instagram-sec .owl-carousel .owl-item {
	transition-timing-function: ease-in!important
}

.MagicScroll[data-mode=carousel],
.MagicScroll[data-mode=cover-flow] {
	background: 0 0;
	padding-bottom: 200px
}

.MagicScroll a {
	font-size: 0!important
}

.instagram-sec .sec-main-h {
	margin-bottom: 1rem
}

.instagram-wrap:before {
	content: "";
	position: absolute;
	width: 40%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: auto;
	right: 0;
	z-index: 999;
	background: rgba(255, 255, 255, 0)
}

.instagram-wrap:after {
	content: "";
	position: absolute;
	width: 40%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: auto;
	z-index: 999;
	background: rgba(255, 255, 255, 0)
}

.h-address {
	padding-bottom: 0
}

.h-address,
.h-address.dark {
	background: #0e1756
}

.h-address:before {
	background-color: rgba(255, 255, 255, .3)
}

.h-address:after {
	display: none
}

.h-address ul {
	width: 100%;
	padding: 0
}

.h-address li {
	padding-left: 30px;
	padding-right: 30px;
	list-style: none;
	min-height: 350px;
	border-top: 1px solid rgba(255, 255, 255, .3);
	padding-top: 60px;
	position: relative;
	display: flex;
	align-items: center
}

.h-address li:before {
	content: "";
	display: block;
	width: 1px;
	height: 50px;
	position: absolute;
	bottom: auto;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: rgba(255, 255, 255, .3)
}

.h-address li:first-child,
.h-address li:last-child {
	border-top: none
}

.h-address li:first-child::after,
.h-address li:last-child::after {
	content: "";
	width: 50%;
	background: rgba(255, 255, 255, .3);
	height: 1px;
	top: 0;
	position: absolute;
	left: auto;
	right: 0
}

.h-address li:last-child::after {
	right: auto;
	left: 0
}

.h-add-inner {
	text-align: center
}

.h-add-inner h3 {
	font-size: 4rem;
	font-family: Sen;
	letter-spacing: .2em;
	text-transform: uppercase;
	color: #3aa6f5
}

.h-add-inner h4 {
	font-size: 1.3rem;
	font-family: Sen;
	letter-spacing: .2em;
	text-transform: uppercase
}

.h-add-inner h5 {
	font-size: .8rem;
	font-weight: 200
}

.h-address li:hover .addbig {
	max-height: 300px;
	transition: all 1s ease
}

.h-address li:hover h3 {
	color: #fff
}

.addbig {
	max-height: 0;
	overflow: hidden;
	transition: all 1s ease
}

.addbig p {
	font-size: .8rem;
	line-height: 2em;
	text-transform: uppercase;
	margin-top: 1rem;
	margin-bottom: 1.5rem
}

.tile-1 img {
	display: block;
	max-width: 100%
}

.item>a {
	display: block;
	overflow: visible
}

.owl-carousel .owl-stage-outer {
	padding-top: 0;
	padding-bottom: 30px
}

.main-tilt {
	max-width: 450px;
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	transform-style: preserve-3d;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding: 30px;
	transition: all .3s ease
}

.center .main-tilt {
	padding: 10px;
	transition: all .3s ease-in
}

.center .main-tilt:hover {
	transition: all 0s ease-in
}

.inner-tilt {
	width: 80%;
	height: auto;
	transform: translateZ(50px);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 80%
}

.inner-tilt img {
	max-width: 100%
}

.inner-tilt {
	background: #000
}

.inner-img {
	opacity: .5
}

.main-tilt .main-back {
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%
}

.slide-cont {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	color: #fff;
	z-index: 99999;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center
}

.active.center .slide-cont {
	box-shadow: 12px 12px 21px -1px rgba(0, 0, 0, .7)
}

.slide-cont h3,
.slide-cont p,
.slide-cont span {
	color: #fff
}

.slide-cont>div {
	width: 100%;
	height: auto
}

.slide-cont {
	font-size: 14px
}

.slide-cont h3,
.slide-cont p,
.slide-cont span {
	display: block;
	width: 100%;
	text-align: center;
	text-transform: uppercase
}

.slide-cont h3,
.slide-cont p {
	font-family: Sen
}

.slide-cont h3 {
	font-size: 1.7em
}

.slide-cont p {
	font-size: 1em
}

.slide-cont span {
	font-style: italic;
	font-weight: 500;
	font-size: .8em
}

.h-charter-sec .owl-nav {
	text-align: center;
	width: 100%;
	position: static;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 59px;
	z-index: 999
}

.h-charter-sec .owl-dots {
	display: none
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
	background: 0 0;
	color: inherit;
	border: none;
	padding: 0!important;
	font: inherit;
	background: #fff;
	padding: 16px 24px!important;
	border-radius: 4px;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 30px
}

.owl-carousel .owl-nav button.owl-next {
	padding: 15px
}

.owl-carousel .owl-nav button.owl-prev {
	padding: 15px
}

footer p {
	font-size: 1rem;
	margin-bottom: 1em
}

footer .dropdown-menu {
	background: rgba(0,0,0,0.3);
}

.footerdark {
	background-color: #1b1d2f;
	color: rgba(255, 255, 255, .6)
}

.footerdark a {
	color: rgba(255, 255, 255, .6)
}

.footerdark .footer-top-menu a {
	color: rgba(255, 255, 255, .6)
}

.footerdark a:hover {
	color: #3aa6f5
}

.footer-top-menu a {
	font-size: .8rem;
	text-transform: uppercase
}

.footerdark .footer-top-menu a:hover {
	color: #3aa6f5
}

.footer-top-menu {
	padding: 0;
	display: flex;
	justify-content: space-between;
	text-align: center;
	border-bottom: 1px solid rgba(255, 255, 255, .3);
	padding-bottom: 5rem
}

.footer-top-menu li {
	width: 100%;
	text-align: center;
	border-right: 1px solid rgba(255, 255, 255, .3);
	list-style: none
}

.footer-top-menu li:last-child {
	border: none
}

footer .social li .dropdown .btn {
	background: 0 0;
	border: none;
	border-radius: 0;
	color: #fff;
	font-weight: 200
}

footer .social li .dropdown .btn:focus {
	box-shadow: none
}

footer .social li>a {
	color: #fff
}

footer .social li>a:hover {
	color: #3aa6f5
}

.copysec {
	text-align: center;
	font-size: .9rem;
	letter-spacing: 0;
	color: #fff
}

.copysec p {
	font-size: .7rem
}

footer .navbar-brand:hover {
	color: #3aa6f5
}

footer.footerdark .navbar-brand:hover {
	color: #fff
}

footer .social {
	margin-bottom: 0
}

footer .social li {
	display: inline-block
}

footer .social li a {
	padding-left: 15px;
	padding-right: 15px
}

.h-newslatter {
	background-size: cover;
	background-repeat: no-repeat
}

.h-newslatter h2 {
	color: #fff
}

.social li a:hover {
	color: #fff
}

.h-newslatter .btn,
.h-newslatter .form-control {
	border-radius: 0;
	padding: 1rem 4rem;
	max-height: auto;
	height: auto
}

.h-newslatter .form-control {
	padding: 1rem 15px;
	min-width: 450px
}

.h-newslatter .btn {
	color: #fff;
	border-color: #fff
}

.h-newslatter .btn:hover {
	background: #3aa6f5;
	color: #fff
}

.h-newslatter .thm-form {
	-ms-flex-align: inherit;
	align-items: inherit
}

.h-newslatter .thm-form .field {
	margin-top: 0;
	margin-bottom: 0;
	background: 0 0;
	border-bottom: 1px solid #bbd5d8;
	height: auto
}

.h-newslatter .thm-form .field.active {
	border-color: #fff
}

.h-newslatter .thm-form .field .form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none
}

.h-newslatter .thm-form .field label {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .18em
}

.h-newslatter .thm-form .field .form-control {
	color: #fff;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: .1em
}

.h-newslatter .read-more.button-fancy:hover {
	color: #fff
}

.h-newslatter .button-fancy.-color-1:hover>.btn-arrow::after,
.h-newslatter .button-fancy.-color-1:hover>.btn-arrow::before {
	background-color: #fff
}

.h-newslatter .button-fancy.-color-1:hover>.btn-arrow::after,
.h-newslatter .button-fancy.-color-1:hover>.btn-arrow::before {
	background-color: #fff
}

.h-newslatter .button-fancy.-color-1:hover>.btn-arrow {
	background: #fff
}

.submit_news {
	border: 1px solid #bfdadd;
	position: relative;
	transition: all .4s ease-in;
	transform: perspective(200px) rotateX(0) rotateY(0);
	transform-style: preserve-3d;
	cursor: pointer
}

.submit_news::before {
	content: "";
	display: block;
	background: #bfdadd;
	width: 50%;
	height: 3px;
	position: absolute;
	top: 0;
	right: 0;
	transform: skewY(0);
	transform-origin: 100% 100%;
	opacity: 0;
	transition: all .2s ease
}

.submit_news::after {
	content: "";
	display: block;
	background: #36607b;
	width: 50%;
	height: 3px;
	position: absolute;
	top: 0;
	left: 0;
	transform: skewY(0);
	transform-origin: 0 100%;
	opacity: 0;
	transition: all .2s ease
}

.submit_news:hover::after,
.submit_news:hover::before {
	opacity: 1;
	transition: all .5s ease .3s;
	box-shadow: 0 9px 8px 1px rgba(0, 0, 0, .3);
	height: 5px
}

.submit_news:hover::after {
	transform: skewY(28deg)
}

.submit_news:hover::before {
	transform: skewY(-28deg)
}

.submit_news:hover {
	border-color: #fff;
	transition: all .4s ease-in;
	transform-style: preserve-3d;
	background: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px
}

.submit_news .submit-arrow {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 100%;
	height: 60%;
	z-index: 1;
	transition: all .5s ease;
	transition-delay: 0
}

.submit_news .submit-arrow img {
	width: 100%;
	height: 100%
}

.submit_news input[type=submit] {
	height: 100%;
	background: 0 0;
	border: none;
	box-shadow: none;
	padding: .6rem 35px;
	cursor: pointer;
	position: relative;
	z-index: 99;
	transition: all .5s ease
}

.submit_news:hover input[type=submit] {
	padding: 1rem 45px;
	transition: all .3s ease
}

.submit_news:hover .submit-arrow {
	right: -200%;
	transition: all .5s ease
}

.footer-logo {
	margin-top: 30px;
	text-align: center;
	position: relative;
	z-index: 99
}

.footer-logo .navbar-brand {
	background: #06062a;
	margin-left: auto;
	margin-right: auto;
	padding: 0 20px;
	border: none
}

.footer-logo::before {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background: rgba(255, 255, 255, .1);
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: -1
}

@media screen and (max-width:1199px) {
	.header-home-4>video {
		width: auto;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 0;
		right: 0
	}
	.instagram-wrap::after,
	.instagram-wrap::before {
		display: none
	}
}

@media screen and (max-width:991px) {
	html {
		font-size: 12px
	}
	.toggle-dot {
		margin: 5px
	}
	.slide-cont {
		margin-top: 0
	}
	.h-charter-sec .owl-nav {
		position: static
	}
}

@media screen and (max-width:767px) {

	html {
		font-size: 11px
	}
	.header-home-2 {
		height: inherit;
		min-height: inherit;
	}
	.header-home-2 .header-cont {
		margin-top: 100px;
	}
	.header-home-2 h1 {
		font-size: 32px
	}
	.header-home-2 h5 {
		font-size: 16px;
	}
	.menu-2 .navbar-nav {
		-ms-flex-direction: column;
		flex-direction: column
	}
	.thm-h {
		margin-bottom: 2.3rem
	}
	.half-section .writing-sec p {
		margin-bottom: 2.3rem
	}
	.thm-h h2 {
		font-size: 1.6rem
	}
	.h-newslatter .form-control {
		padding: 1rem 8px;
		min-width: 180px
	}
	.section-menu .main-section-menu {
		margin: 0 -15px;
		position: relative
	}
	.main-section-menu li a {
		font-size: .9rem
	}
	.main-section {
		padding-top: 3rem;
		padding-bottom: 3rem
	}
	.main-section:after,
	.main-section:before {
		height: 3rem
	}
	.button-fancy {
		font-size: .9rem;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		display: inline-block;
		padding: 1.5rem 2rem;
		text-decoration: none;
		position: relative
	}
	img.arrow-down,
	img.arrow-left,
	img.arrow-right,
	img.arrow-up {
		max-width: 12px
	}
	.h-address li {
		padding-left: 0;
		padding-right: 0;
		list-style: none;
		min-height: 350px;
		border-top: 1px solid rgba(255, 255, 255, .3);
		padding-top: 0;
		position: relative;
		display: flex;
		align-items: center
	}
	.h-add-inner h3 {
		font-size: 2rem;
		font-family: Sen;
		letter-spacing: .2em;
		text-transform: uppercase;
		color: #3aa6f5
	}
	.h-add-inner h4 {
		font-size: 1.1rem
	}
	.menu-2 .navbar-toggler {
		width: auto
	}

}

@media screen and (max-width:575px) {
	.h-our-history-2 .half-left img {
		position: static;
	}
	.h-our-history-2 .half-left::before {
		display: none
	}
	.half-left .img-sec,
	.half-right .img-sec {
		margin-right: 0;
		margin-left: 0
	}
	.main-section-menu.d-flex {
		flex-wrap: wrap
	}
	.section-menu .main-section-menu li {
		width: 50%
	}
	.section-menu.secmenu-type-1 .main-section-menu li {
		border-top: 0
	}
	.section-menu.secmenu-type-1 .main-section-menu li:first-child {
		border-left: 1px solid #fff
	}
	.section-menu.secmenu-type-1 .main-section-menu li,
	.section-menu.secmenu-type-1 .main-section-menu li:last-child {
		border-right: 1px solid #fff
	}
	.header-home-2.header-scrolled,
	.header-home-4.header-scrolled {
		margin: 10px 10px 0
	}
	.menu-2.menu-2-scrolled>div {
		width: calc(100% - 20px)
	}
}

body.home-3 {
	color: #32325d
}

body.home-3 .main-section-menu li a:hover {
	background: #32325d;
	color: #fff;
	border-color: #32325d
}

body.home-3 .main-section.dark {
	background: #f6f9fc;
	color: #32325d
}

body.home-3 .dark li {
	color: #32325d
}

body.home-3 .h-add-inner h3 {
	color: #3aa6f5
}

body.home-3 .menu-2-inner a:not(.navbar-brand):hover {
	background: #32325d;
	color: #fff
}

body.home-3 .section-menu {
	margin-top: 30px
}

body.home-3 .h-address li::after,
body.home-3 .h-address li::before,
body.home-3 .h-address::before {
	background-color: #dfdfdf
}

body.home-3 .h-address li {
	border-color: #dfdfdf
}

.header-inner {
	min-height: 0;
	padding-top: 14rem;
	color: #fff;
	background-size: cover;
	background-position: center bottom;
	margin-bottom: 4rem;
	padding-bottom: 1px;
	background-blend-mode: multiply
}

.header-inner .y-breadcrum ul {
	background: 0 0
}

.header-inner .y-breadcrum span {
	color: #fff
}

.header-inner .y-breadcrum h1 {
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 50px
}

.header-inner .y-breadcrum a:hover {
	color: #fff
}

form.y-form input::-webkit-input-placeholder,
form.y-form textarea::-webkit-input-placeholder {
	color: #121730
}

form.y-form input::-moz-placeholder,
form.y-form textarea::-moz-placeholder {
	color: #121730
}

form.y-form input:-ms-input-placeholder,
form.y-form textarea::-moz-placeholder {
	color: #121730
}

form.y-form input:-moz-placeholder,
form.y-form textarea::-moz-placeholder {
	color: #121730
}

/*div.container-fluid .y-arrow_line {
	top: -25px;
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	right: 30px;
	width: 74px;
	z-index: 999
}

div.container-fluid .y-arrow_line i.y-left_anchor {
	left: -2px
}

div.container-fluid .y-arrow_line img {
	position: absolute;
	display: block;
	font-size: 24px;
	top: -12px;
	cursor: pointer
}

div.container-fluid .y-arrow_line img.y-right_anchor {
	right: -2px
}

div.container-fluid .y-arrow_line img {
	position: absolute;
	display: block;
	font-size: 24px;
	top: -12px;
	cursor: pointer
}*/

.y-fun_facts {
	position: relative
}

.y-fun_facts_container {
	position: relative;
	z-index: 999;
	border: 1px solid rgba(255, 255, 255, .6);
	width: calc(100% - 60px);
	max-width: 100%;
	padding-top: 40px
}

.y-fun_facts:after {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(8, 28, 78, .6);
	position: absolute
}

.y-fun_facts_block {
	position: relative;
	z-index: 999
}

.y-product_listing_side .y-dest_list>div:nth-child(2n+1) {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse
}

.y-pagination {
	padding-top: 0;
	width: 100%;
	margin: 3rem 0 6rem;
	padding-right: 15px;
	padding-left: 15px
}

.y-pagination>div {
	padding-top: 0;
	border-top: 1px solid #dfdfdf;
	padding-top: 7px;
	border-bottom: 1px solid #dfdfdf;
	padding-bottom: 0
}

.y-dest_list_single .thm-h {
	margin-bottom: 3.5rem
}

.responsive-tabs__list__item {
	font-weight: 400;
	margin-bottom: 0!important
}

.responsive-tabs__panel {
	margin-bottom: 7rem;
	padding: 40px 15px 15px;
	border: 1px solid #dfdfdf;
	border-top: none
}

.responsive-tabs__panel p {
	margin-top: 0;
	margin-bottom: .5em
}

.y-client_testimonials .y-client_testimonial_rating i {
	color: #3aa6f5
}

.header-inner.hi-charter {
	background-position: center center;
	background-size: 100%
}

.y-yacht_intro .button-fancy {
	margin-left: auto;
	margin-right: auto
}

.y-yacht_intro .button-fancy {
	font-weight: 500
}

.y-slideshow .inner-tilt {
	background: 0 0;
	transition: all .5s ease
}

.y-slideshow .inner-tilt h3:after,
.y-slideshow .inner-tilt h3:before {
	display: none
}

.y-slideshow .main-tilt .slide-cont {
	box-shadow: none;
	transition: all .5s ease
}

.y-slideshow .main-tilt:hover .inner-tilt {
	background: #000;
	transition: all .5s ease
}

.y-slideshow .main-tilt:hover .slide-cont {
	box-shadow: 0 0 21px -1px rgba(0, 0, 0, .7);
	transition: all .5s ease
}

.y-slideshow .owl-dots {
	display: none!important
}

.y-slideshow .owl-nav {
	text-align: center;
	width: 100%;
	margin: auto
}

.y-slideshow .owl-theme .owl-nav div.owl-next,
.y-slideshow .owl-theme .owl-nav div.owl-prev,
.y-slideshow .owl-theme div.owl-dot {
	background: 0 0;
	color: inherit;
	border: none;
	padding: 0!important;
	font: inherit
}

.y-slideshow .owl-theme .owl-nav div.owl-prev {
	margin-right: 24%;
	padding: 15px
}

.y-slideshow .owl-theme .owl-nav div.owl-next {
	margin-left: 24%;
	padding: 15px
}

.y-boat_type .owl-theme .owl-controls .owl-nav [class*=owl-] {
	color: #3aa6f5;
	cursor: pointer;
	font-size: 65px;
	line-height: 1em;
	padding: 0;
	margin: 0
}

.y-contact .y-single_info,
.y-contact_01 .y-single_info,
.y-contact_02 .y-single_info {
	margin-bottom: 7rem
}

.y-contact_01 .y-form input,
.y-contact_01 .y-form textarea {
	background: #efefef
}

.example-classname {
	border: 8px solid red
}

.charter-carousel .item a img {
	height: 150px;
	object-fit: cover;
	transition: all .3s ease
}

.charter-carousel .item a {
	display: block;
	height: 150px;
	transition: all .3s ease .3s;
	overflow: hidden
}

.charter-carousel .center .item a {
	height: 230px;
	transition: all .3s ease
}

.charter-carousel .center .item a img {
	height: 230px;
	object-fit: cover;
	transition: all .3s ease
}

.charter-carousel .owl-dots {
	max-width: 900px;
	text-align: center;
	margin: auto;
	margin-top: 43px
}

.charter-carousel .owl-dot.active {
	display: block;
	-webkit-animation-name: menuanimation;
	-webkit-animation-duration: .3s;
	animation-name: menuanimation;
	animation-duration: .3s
}

.charter-carousel .owl-dot {
	display: none
}

.charter-carousel .owl-dot h4 {
	font-weight: 700
}

.charter-carousel .owl-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.charter-carousel.owl-carousel .owl-nav {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 0;
	max-width: 53%;
	z-index: 99;
	position: relative;
	top: -66px
}

.charter-carousel.owl-carousel .owl-nav::after {
	content: "";
	width: calc(100% - 45px);
	position: absolute;
	top: 73px;
	height: 0;
	border-top: 1px solid #4e4e4e;
	left: 0;
	right: 0;
	margin: auto;
	opacity: .2
}

.charter-carousel.owl-carousel .owl-nav .owl-next,
.charter-carousel.owl-carousel .owl-nav .owl-prev {
	font-size: 44px!important;
	padding: 10px 20px!important;
	margin: 0!important;
	background: 0 0
}

.servicebox-first {
	background-color: rgba(7, 28, 78, .5);
	background-blend-mode: multiply;
	height: calc(100% - 30px);
	background-size: cover;
	background-position: center center;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 5px;
	padding: 15px;
	position: relative
}

.servicebox-first::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-right: 15px solid #fff;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	position: relative;
	left: 15px
}

.servicebox-first .thm-h {
	margin-bottom: 0
}

.servicebox-first p {
	font-size: 18px;
	line-height: 1.8em
}

.servicebox {
	background: #e6f0f6;
	padding: 20px;
	margin-bottom: 30px;
	border-radius: 5px;
	position: relative
}

.servicebox-row>div:nth-child(1) .servicebox {
	background: #e6f0f6
}

.servicebox-row>div:nth-child(2) .servicebox {
	background: #f7f5f4
}

.servicebox-row>div:nth-child(3) .servicebox {
	background: #f3f2f4
}

.servicebox-row>div:nth-child(4) .servicebox {
	background: #fcf0e4
}

.servicebox-row>div:nth-child(5) .servicebox {
	background: #f4f6f8
}

.servicebox-row>div:nth-child(6) .servicebox {
	background: #effafb
}

.servicebox img {
	max-height: 45px;
	position: relative;
	transition: all .4s;
	top: 0
}

.servicebox:hover img {
	top: -45px;
	transition: all .3s
}

.servicebox h4 {
	font-size: 18px;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 20px;
	top: 0;
	position: relative;
	transition: all .3s
}

.servicebox:hover h4 {
	top: -45px;
	transition: all .3s .2s
}

.servicebox p {
	font-size: 15px;
	margin: 0;
	top: 0;
	position: relative;
	transition: all .3s
}

.servicebox:hover p {
	top: -45px;
	transition: all .3s .4s
}

.servicemore {
	opacity: 0;
	bottom: -45px;
	position: absolute;
	font-weight: 700;
	transition: all .1s;
	visibility: hidden
}

.servicebox:hover .servicemore {
	bottom: 20px;
	opacity: 1;
	transition: all .3s .5s;
	visibility: visible
}

.servicebox .servicemore:hover {
	color: #000;
	transition-delay: 0s
}

.testimonial {
	background-repeat: no-repeat;
	background-position: bottom 75px left;
	background-color: #bfdadd;
	background-size: 300px;
	color: #36607b;
	-webkit-animation-name: testimonialani;
	-webkit-animation-duration: .3s;
	animation-name: testimonialani;
	animation-duration: .3s
}

@-webkit-keyframes testimonialani {
	0% {
		transform: translateY(-10%);
		opacity: 0
	}
	100% {
		transform: translateY(0);
		opacity: 1
	}
}

@keyframes testimonialani {
	0% {
		transform: translateY(-10%);
		opacity: 0
	}
	100% {
		transform: translateY(0);
		opacity: 1
	}
}

.testimonail_box {
	text-align: center;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto
}

.testimonail_slider .testimonail_user img {
	max-width: 100%;
	width: 100px;
	padding: 7px;
	margin: 20px auto;
	border: 1px solid rgba(14, 23, 86, .1);
	border-radius: 100%
}

.testimonail_slider .owl-dots {
	text-align: center;
	vertical-align: middle
}

.testimonail_slider .owl-dot span {
	width: 15px;
	height: 15px;
	margin-top: 0;
	margin-left: 4px;
	border-radius: 100%;
	display: block;
	background: rgba(14, 23, 86, 0)
}

.testimonail_slider .owl-dot.active span {
	background: #36607b;
	display: block
}

.testimonail_slider .owl-dot {
	width: 23px;
	height: 23px;
	background: rgba(14, 23, 86, .1)!important;
	border-radius: 100%;
	margin-left: 5px;
	margin-right: 5px
}

.home-booknow {
	padding: 15px
}

.home-booknow:after {
	content: "";
	display: block;
	clear: both
}

.booknow_box {
	background-color: #bfdadd;
	margin-top: 20px;
	padding: 30px 30px 50px;
	background-image: linear-gradient(45deg, #bfdadd, #f0f9fa)
}

.booknow_box h4 {
	margin-bottom: 30px;
	font-weight: 700;
	color: #36607b
}

.btn-primary {
	color: #fff;
	background-color: #05c1eb;
	background-image: linear-gradient(45deg, #3aa6f5, #21cff6);
	border-color: #007bff;
	padding: 15px
}

.btn-primary:hover {
	background-image: linear-gradient(0deg, #007bff, #007bff)
}

.booknow_box .btn-primary {
	width: 100%
}

.booknow_box .form-control {
	border: 1px solid #38999e;
	color: #36607b
}

.form-control::-webkit-input-placeholder {
	opacity: 1;
	color: inherit
}

.form-control::-moz-placeholder {
	opacity: 1;
	color: inherit
}

.form-control:-ms-input-placeholder {
	opacity: 1;
	color: inherit
}

.form-control:-moz-placeholder {
	opacity: 1;
	color: inherit
}

.footer-1 .h-newslatter .thm-form .field {
	width: 100%;
	margin-bottom: 10px;
	text-align: center
}

.footer-1 .h-newslatter .form-control {
	min-width: inherit;
	text-align: center
}

.footer-1 .thm-form .field label {
	left: 0;
	right: 0;
	letter-spacing: 0;
	opacity: .6;
	text-transform: inherit
}

.footer-1 .h-newslatter .thm-form .field .form-control {
	width: 100%
}

.footer-1 .h-newslatter .thm-h {
	margin-bottom: 30px
}

.footer-box h2 {
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase
}

.footer-box h4 {
	font-size: 14px
}

.footer-1 .footer-box {
	text-align: left
}

.footer-box ul {
	padding: 0
}

.footer-box ul li {
	margin-bottom: .3em
}

.footer-box p {
	font-size: 1rem;
	margin-bottom: 1em
}

.textwidget {
	margin-top: 30px
}

.textwidget li {
	list-style: none
}

.footer-box .tagnav {
	padding: 0
}

.footer-box .tagnav li {
	width: auto;
	margin: 3px 0
}

.h2-book-section {
	background-image: url('/assets/images/kopaonik_bg.jpg');
	background-attachment: fixed;
	background-size: cover;
	background-position: center bottom;
	min-height: inherit;
	transition: all .5s ease-in;
	padding-left: 45px
}

.h2-book-section .booknow_box {
	margin-top: 60px;
	background: rgba(1, 20, 104, .5);
}

.h2-book-section .booknow_box h4 {
	color: #fff
}

.h2-book-section .home-about p {
	font-size: 1.4rem;
	text-align: left;
	line-height: 2em;
	font-family: Sen
}

.h2-book-section .home-about {
	position: relative;
	z-index: 99
}

.h2-book-section {
	position: relative
}

.h2-book-section .home-about {
	margin: 80px auto 0
}

.h2-book-section::after {
	content: "";
	display: block;
	width: calc(50% + 23px);
	background: rgba(255,255,255,0.75);
	left: 0;
	top: 0;
	position: absolute;
	bottom: 0;
	margin: auto;
	height: 100%;
}

.menu-2 .sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 999;
	top: 0;
	right: -30px;
	background-color: rgba(1, 20, 104, 1);
	background-image: linear-gradient(45deg, rgba(1, 20, 104, 1), #85acff 80%);
	overflow-x: hidden;
	transition: .5s;
	padding-top: 75px;
	box-shadow: 0 0 25px 8px rgba(44, 71, 74, .4)
}

.menu-2 .cart_index {
	background-color: rgba(1, 20, 104, 1);
	background-image: linear-gradient(45deg, rgba(1, 20, 104, 1), rgba(1, 20, 104, 1) 80%)
}

.menu-2 .nav-search .nav-search-form.search-open {
	background-color: rgba(1, 20, 104, 1);
	background-image: linear-gradient(45deg, rgba(1, 20, 104, 1), rgba(1, 20, 104, 1) 80%)
}

.h2-book-section .booknow_box .form-control {
	background: 0 0;
	border: none;
	border-radius: 0;
	padding: 10px 15px;
	color: rgba(255, 255, 255, .9);
	border-bottom: 1px solid #43dfe6
}

.h2-book-section .booknow_box .form-control:focus {
	box-shadow: none;
	border-color: none;
	background-color: rgba(0, 0, 0, .3)
}

#main-2 footer .h-newslatter {
	background-color: rgba(1, 20, 104, 1);
	background-image: linear-gradient(45deg, rgba(1, 20, 104, 1), #12757d 80%)
}

#main-2 .main-section.dark {
	background-color: rgba(1, 20, 104, 1)
}

#main-2 .footerdark {
	background-color: rgba(1, 20, 104, 1);
	color: rgba(255, 255, 255, .6)
}

#main-2 .footer-logo .navbar-brand {
	background-color: rgba(1, 20, 104, 1)
}

#main-2 footer.swip {
	min-height: inherit
}

#main-2 .thm-h h2 {
	font-family: Sen;
	letter-spacing: .03em;
	margin-bottom: .3em;
	text-transform: uppercase;
	font-weight: 700;
	color: #03717c
}

#main-2 .button-fancy>.btn-arrow {
	background-color: #05c1eb;
	background-image: linear-gradient(45deg, #03717c, #21cff6)
}

#main-2 .h-charter {
	background-repeat: no-repeat;
	background-size: 300px;
	background-position: center bottom -150px
}

.main-section.h-our-history-3 {
	padding-top: 0;
	padding-bottom: 0
}

.h-our-history-3 {
	overflow: hidden
}

.h-our-history-3 .half-right {
	padding-top: 75px;
	padding-bottom: 75px
}

.h-our-history-3 .jumbotron {
	margin-bottom: 0
}

#main-2 .half-section .writing-sec {
	background: #fff;
	padding: 30px;
	border-radius: 6px;
	box-shadow: 10px 15px 95px -5px rgba(3, 81, 89, .1);
	position: relative
}

#main-2 .half-section {
	overflow: hidden
}

#main-2 .half-section .writing-sec::after {
	content: "";
	width: 88px;
	height: 100px;
	background: 0 0;
	border: 10px solid #cbd0d1;
	display: block;
	position: absolute;
	top: -30px;
	left: -30px;
	opacity: .3;
	z-index: -1
}

.par-img img {
	opacity: 0
}

.menu-2.menu-3-color>div {
	transition: all .5s ease-in;
	background: rgba(0, 12, 30, 0)
}

.menu-2.menu-2-scrolled.menu-3-color>div {
	transition: all .5s ease-in;
	background: rgba(0, 12, 30, .9)
}

.menu-2.menu-3-color .sidenav {
	background-color: #0e0a00;
	background-image: linear-gradient(45deg, #0e0a00, #261c00 80%);
	box-shadow: none
}

.menu-2.menu-3-color .sidenav .tagnav li a:hover {
	background: #a3832b
}

#main-3 .half-section,
#main-3 .main-section {
	overflow: hidden
}

.h3-book-section {
	background: #000c1e;
	box-shadow: 0 -17px 11px 26px rgba(0, 0, 0, .3);
	position: relative;
	z-index: 9
}

.h3-book-section .booknow_box {
	background: 0 0;
	margin-top: 0;
	padding-top: 15px;
	padding-bottom: 15px
}

.h3-book-section .booknow_box h4 {
	font-size: 15px;
	margin: 0;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .3em;
	margin-right: 30px
}

.h3-book-section .booknow_box .btn-primary {
	width: auto;
	padding-left: 50px;
	padding-right: 50px;
	background: 0 0;
	border: 1px solid rgba(255, 255, 255, .7);
	padding: 6px 50px;
	margin-left: 30px
}

.h3-book-section .booknow_box .btn-primary:hover {
	background: #fff;
	border: 1px solid rgba(255, 255, 255, .1);
	padding: 6px 50px;
	color: #000c1e
}

.h3-book-section .booknow_box .form-control {
	color: rgba(255, 255, 255, .7);
	background: 0 0;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, .7);
	border-radius: 0;
	margin-left: 15px;
	margin-right: 15px
}

.h3-book-section .booknow_box .form-control:focus {
	color: #fff;
	box-shadow: none
}

.h3-about-section {
	background: url('/assets/images/koprent_slike/vikend_naselje/bg_1.png');
	background-position: center right;
	background-repeat: no-repeat
}

.home-about-3 {
	max-width: 600px;
	margin: 170px auto;
	text-align: left
}

.thm-h-3.thm-h {
	position: relative
}

.thm-h-3.thm-h h4 {
	font-family: Sen;
	letter-spacing: .03em;
	margin-bottom: .3em;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 2rem;
	opacity: 1;
	position: relative
}

.thm-h-3.thm-h h4:after {
	content: "";
	display: block;
	width: 100px;
	height: 2px;
	background: #ddc29a;
	margin-top: 30px;
	margin-bottom: 30px
}

.thm-h-3.thm-h.text-center h4:after {
	margin-left: auto;
	margin-right: auto
}

.thm-h-3.thm-h h2 {
	font-size: 107px;
	width: auto;
	opacity: .07;
	white-space: nowrap;
	line-height: .5
}

#main-3 .half-section .thm-h-3.thm-h h2 {
	line-height: 0
}

#main-3 .half-section .writing-sec {
	background: #fff;
	padding: 30px;
	border-radius: 6px;
	box-shadow: 10px 15px 95px -5px rgba(3, 81, 89, .1)
}

#main-3 .charter-carousel-2 {
	background: #f4f4f4;
	margin-left: -30px;
	margin-right: -30px;
	padding-left: 30px;
	padding-right: 30px;
	width: auto;
	margin-top: 100px;
	padding-bottom: 50px
}

.charter-carousel-2.owl-carousel .owl-nav button.owl-next {
	background: 0 0
}

.charter-carousel-2.owl-carousel .owl-nav button.owl-prev {
	background: 0 0
}

.explore-section {
	padding-top: 0;
	padding-bottom: 0
}

.explore-section .row {
	overflow: hidden;
}

.explore_box {
	position: relative
}

.explore_box>a {
	display: block;
	position: relative
}

.explore_box>a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	border: 8px solid #ddc29a;
	transform: scale(1);
	z-index: 99;
	transition: all .4s;
	opacity: 0
}

.explore_box>a:hover::before {
	content: "";
	transform: scale(.9);
	opacity: .5;
	transition: all .6s
}

.explore_box .thm-h {
	position: absolute;
	top: 13%;
	left: 10%;
	z-index: 99
}

.explore_box .thm-h h4 {
	text-transform: capitalize;
	color: #fff
}

.explore_box.exb-type-2 .thm-h h4 {
	color: #121730
}

.explore_box.exb-type-2 .img-fluid-i {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: .05;
	max-width: 180px;
	margin: auto;
	transform: translateY(-50%)
}

.exb-type-2.explore_box .thm-h {
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	text-align: center
}

.exb-type-2.explore_box .thm-h {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.explore_box img.img-fluid {
	max-width: inherit;
	width: 100%;
	max-height: 65vh;
	object-fit: cover;
	transition: all .4s
}

.explore_box:hover img.img-fluid {
	filter: contrast(140%);
	transition: all .4s
}

.exb-1 img.img-fluid {
	max-width: inherit;
	width: 112%;
	position: relative;
	z-index: 9
}

.owl-carousel.charter-carousel-2 .owl-nav button.owl-next,
.owl-carousel.charter-carousel-2 .owl-nav button.owl-prev {
	margin-bottom: 0
}

.charter-carousel-2 .owl-nav {
	width: 300px;
	display: inline-block;
	vertical-align: middle
}

.charter-carousel-2 .owl-dots {
	width: calc(100% - 320px);
	display: inline-block;
	vertical-align: middle;
	text-align: right
}

.charter-carousel-2 .owl-dots .owl-dot {
	display: inline-block;
	vertical-align: middle;
	margin-right: 15px
}

.charter-carousel-2 .owl-dots .owl-dot:before {
	content: "";
	display: inline-block;
	width: 30px;
	background: #a87e0a;
	height: 1px;
	transition: all .4s;
	margin-right: 15px
}

.charter-carousel-2 .owl-dots .owl-dot.active:before {
	width: 100px;
	transition: all .4s
}

.charter-carousel-2 .owl-dots {
	counter-reset: dots
}

.charter-carousel-2 .owl-dot:after {
	counter-increment: dots;
	content: counter(dots);
	font-style: italic;
	font-size: 19px
}

.count_single {
	padding: 70px 50px;
	position: relative;
	min-width: 25vw
}

.count_single::before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background: #ddc29a;
	position: absolute;
	right: -15px;
	top: 0
}

.count_single::after {
	content: "";
	display: block;
	width: 40px;
	height: 80px;
	background-color: #fff;
	position: absolute;
	right: -35px;
	top: 0;
	bottom: 0;
	margin: auto;
	background-size: auto 40px;
	background-repeat: no-repeat;
	background-position: center center
}

.counter_outer .row>div:last-child .count_single::after,
.counter_outer .row>div:last-child .count_single::before {
	display: none
}

.count_num {
	font-size: 50px;
	display: block;
	font-weight: 700
}

.count_title {
	color: rgba(18, 23, 48, .5);
	display: block;
	margin: auto
}

.footer-3.footerdark {
	background-color: #fff;
	background-size: 100% auto;
	background-position: top center;
	background-repeat: no-repeat;
	padding-top: 70vh
}

.footer-3 .h-newslatter .thm-form .field .form-control {
	width: 100%;
	min-width: inherit
}

.footer-3.footerdark .h-newslatter {
	background: 0 0
}

.footer-3.footerdark .main-section.dark {
	background: 0 0
}

.footer-3.footerdark .footer-logo .navbar-brand {
	background: 0 0
}

#main-3 .button-fancy>.btn-arrow {
	background-color: #011468;
	background-image: linear-gradient(45deg, #6380fd, #011468);
	color: #fff
}

#main-3 .button-fancy:hover>.text {
	color: #524929
}

#main-3 .button-fancy:hover.-color-1>.btn-arrow::after,
#main-3 .button-fancy:hover.-color-1>.btn-arrow::before {
	background-color: #524929
}

.header .fa,
.y-boat_type .fa-anchor,
.y-header_outer .header,
.y-header_outer nav,
.y-header_strip .header,
.y-landing .y-landing_list img,
.y-our_services .y-servie_info,
.y-product_filter_side,
.y-product_filter_side.y-side_filter_open,
.y-product_listing>div .m-prod_list_text,
.y-side_tags_list li,
.y-transtion,
.y-yacht_intro_img img,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
	transition: all .5s ease-out;
	-moz-transition: all .5s ease-out;
	-webkit-transition: all .5s ease-out;
	-o-transition: all .5s ease-out
}

.y-transtion_fast {
	transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-webkit-transition: all .3s ease-out;
	-o-transition: all .3s ease-out
}

.y-loading {
	background: rgba(255,255,255,0.9);
	height: 100%;
	max-width: 100% !important;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99999;
}

.y-loading img {
	width: auto;
	height: auto;
	margin: auto;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	max-width: 210px;
}

.select-wrapper {
	display: inline-block;
	border: 1px solid #06062a;
	cursor: pointer;
	background-size: 12px;
	background-color: #06062a
}

.select-wrapper,
.select-wrapper select {
	height: 40px;
	position: relative;
	line-height: 34px;
	width: 100%
}

.select-wrapper:hover {
}

.select-wrapper .holder {
	color: #fff;
	display: block;
	margin: 0 35px 0 5px;
	font-size: 14px;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	position: relative
}

.select-wrapper select {
	margin: 0;
	position: absolute;
	z-index: 2;
	cursor: pointer;
	outline: 0;
	opacity: 0;
	-khtml-opacity: 0;
	-moz-opacity: 0
}

.y-button:hover,
.y-footer .y-special_offer ul li .y-read_more:hover,
.y-home_blog_content .y-btn:hover {
	color: #fff;
	border-color: #3aa6f5;
	background: #3aa6f5;
	cursor: pointer
}

.y-button:active::before,
.y-button:focus::before,
.y-button:hover::before,
.y-footer .y-special_offer ul li .y-read_more:active::before,
.y-footer .y-special_offer ul li .y-read_more:focus::before,
.y-footer .y-special_offer ul li .y-read_more:hover::before,
.y-home_blog_content .y-btn:active::before,
.y-home_blog_content .y-btn:focus::before,
.y-home_blog_content .y-btn:hover::before {
	transform: scaleY(1);
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1)
}

.y-button,
.y-footer .y-special_offer ul li .y-read_more,
.y-home_blog_content .y-btn {
	backface-visibility: hidden;
	border-left: 5px solid #06062a;
	background: #e1e1e1 none repeat scroll 0 0;
	box-shadow: 0 2px 2px #bfbfbf;
	-moz-box-shadow: 0 2px 2px #bfbfbf;
	-webkit-box-shadow: 0 2px 2px #bfbfbf;
	display: inline-block;
	position: relative;
	transform: translateZ(0);
	transition-duration: .3s;
	transition-property: color;
	vertical-align: middle
}

::-webkit-input-placeholder {
	color: #fff;
	opacity: 1
}

:-moz-placeholder {
	color: #fff;
	opacity: 1
}

::-moz-placeholder {
	color: #fff;
	opacity: 1
}

:-ms-input-placeholder {
	color: #fff;
	opacity: 1
}

.video-container {
	position: relative;
	padding-bottom: 55%;
	padding-top: 30px;
	height: 0;
	overflow: hidden
}

.video-container embed,
.video-container iframe,
.video-container object {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.fa-times-circle {
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 38px!important;
	margin: auto;
	text-align: center;
	width: 94%
}

.header .fa {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	height: 20px;
	line-height: 20px;
	padding: 2px;
	text-align: center;
	width: 20px;
	background: 0 0
}

.header .fa:hover {
	background: #06062a
}

form.y-form .y-select_full>span {
	width: 100%
}

form.y-form .y-select_half>span {
	width: 49%
}

form.y-form .y-select_half input {
	width: 49.2%
}

form.y-form .y-bots_types img {
	display: inline-block;
	vertical-align: bottom
}

form.y-form button {
	margin-top: 15px;
	padding: 13px 30px;
	background: 0 0;
	box-shadow: none!important;
	border: 1px solid;
	display: block;
	text-transform: uppercase;
	font-size: 18px;
	color: #fff;
	text-align: center;
	width: auto
}

form.y-form input {
	width: 100%;
	color: #06062a;
	padding: 5px 10px;
	height: 40px;
	line-height: 34px;
	background: #fff;
	border: none;
	margin-bottom: 8px;
	font-size: 14px
}

form.y-form textarea {
	color: #06062a;
	width: 100%;
	height: 70px;
	resize: none;
	padding: 10px;
	background: #fff;
	border: none;
	margin-bottom: 8px;
	font-size: 14px
}

.y-line {
	opacity: .4;
	background: #a0a0a0 none repeat scroll 0 0;
	right: 74px;
	position: absolute;
	top: 1px;
	width: 1px;
	z-index: 999
}

.y-inner_page {
	position: relative;
	margin-top: 60px;
	background: #fff
}

.y-back_to_top {
	bottom: 74.5px;
	color: #5a5a5a;
	cursor: pointer;
	font-size: 20px;
	height: 20px;
	line-height: 20px;
	position: fixed;
	right: 50px;
	text-align: center;
	width: 50px;
	z-index: 99999
}

.camera_prev {
	background: #fff none repeat scroll 0 0;
	left: 0;
	opacity: 1!important
}

.camera_next {
	background: #fff none repeat scroll 0 0;
	right: 0;
	opacity: 1!important
}

.y-landing {
	padding-bottom: 10px
}

.y-landing .y-landing_but {
	position: relative;
	height: 100px
}

.y-landing .y-logo {
	margin-top: 10px;
	display: inline-block;
	top: 125px;
	position: relative
}

.y-landing .header {
	padding-bottom: 10px
}

.y-landing .y-section_inner {
	padding-top: 30px
}

.y-landing .y-section_inner h2 {
	color: #06062a
}

.y-landing .y-landing_list {
	margin-top: 30px
}

.y-landing .y-landing_list>div {
	margin-bottom: 30px
}

.y-landing .y-landing_list img {
	box-shadow: 0 0 5px #d7d7d7;
	-webkit-box-shadow: 0 0 5px #d7d7d7;
	-moz-box-shadow: 0 0 5px #d7d7d7;
	margin: 15px auto 10px;
	max-width: 500px;
	width: 100%
}

.y-landing .y-landing_list img:hover {
	box-shadow: 0 5px 5px #949494;
	-webkit-box-shadow: 0 5px 5px #949494;
	-moz-box-shadow: 0 5px 5px #949494
}

.y-landing .y-landing_list h3 {
	font-size: 20px;
	font-weight: 700;
	color: #06062a;
	text-align: center;
	margin-bottom: 0;
	border: none
}

.y-landing .y-landing_list h3:after {
	display: none
}

.y-footer .y-special_offer ul li .y-landing .y-read_more,
.y-home_blog_content .y-landing .y-btn,
.y-landing .y-button,
.y-landing .y-footer .y-special_offer ul li .y-read_more,
.y-landing .y-home_blog_content .y-btn {
	background: #3aa6f5 none repeat scroll 0 0;
	bottom: 0;
	color: #fff;
	display: block;
	font-size: 22px;
	font-weight: 600;
	height: 50px;
	left: 0;
	letter-spacing: 0;
	line-height: 45px;
	margin: auto;
	position: absolute;
	right: 0;
	text-align: center;
	text-transform: uppercase;
	top: 0;
	width: 250px
}

@media (max-width:1023px) {
	.y-landing .y-logo img {
		max-height: 70px
	}
	.y-landing .y-logo {
		top: 25px
	}
}

.y-header_outer nav {
	border-top: 1px solid #dbdaeb;
	margin-top: 8px;
	text-align: center
}

.y-header_outer nav ul {
	padding-left: 0
}

.y-header_outer nav ul li {
	text-align: left
}

.y-header_outer address {
	padding-top: 10px
}

.y-header_outer address a {
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	font-size: 12px
}

.y-header_outer address a:hover {
	color: #3aa6f5
}

.y-header_outer address i {
	margin-right: 5px;
	font-size: 18px;
	position: relative;
	top: 6px;
	color: #06062a
}

.y-header_outer .y-social {
	padding-top: 0;
	position: absolute;
	right: 15px;
	top: 22px
}

.y-header_outer .y-logo {
	margin-top: 10px;
	display: inline-block
}

.y-header_outer .header:hover {
	background: #fff
}

.y-header_outer .header {
	width: 100%;
	background: rgba(255, 255, 255, .8)
}

.y-form_gap {
	padding-right: 35px
}

.y-header_strip.y-header_outer>div#y-header_form {
	position: static;
	margin-top: -165px
}

.y-header_strip .header {
	position: static;
	top: 0;
	width: 100%;
	background: rgba(255, 255, 255, .8)
}

.y-header_strip .y-logo {
	margin-top: 10px;
	display: inline-block
}

.y-header_strip #y-header_form {
	bottom: 0;
	position: absolute;
	margin-top: auto;
	width: 100%
}

.y-header_strip .y-header_form {
	background-color: rgba(255, 255, 255, .8);
	padding: 10px
}

.y-header_strip .y-header_form .y-form_gap button {
	width: 100%
}

.y-header_strip .y-header_form .y-form_gap i {
	position: relative!important;
	top: 5px;
	color: #fff!important;
	margin-right: 5px
}

.y-header_strip .y-header_form h3 {
	color: #06062a;
	font-size: 18px;
	padding-bottom: 8px;
	margin-bottom: 20px;
	font-weight: 600;
	text-transform: uppercase
}

.y-header_strip .y-header_form .select-wrapper .holder {
	margin-top: 3px;
	margin-left: 8px
}

.y-footer .y-special_offer ul li .y-header_strip .y-header_form .y-read_more,
.y-header_strip .y-header_form .y-button,
.y-header_strip .y-header_form .y-footer .y-special_offer ul li .y-read_more,
.y-header_strip .y-header_form .y-home_blog_content .y-btn,
.y-home_blog_content .y-header_strip .y-header_form .y-btn {
	width: auto;
	display: inline-block;
	background: #3aa6f5;
	border-color: transparent
}

.y-header_strip .y-header_form p {
	margin: auto auto 15px;
	font-size: 14px;
	line-height: 14px;
	color: #fff;
	text-transform: capitalize
}

.y-header_strip .y-header_form p a {
	color: #007ac2;
	font-weight: 600;
	font-style: italic;
	margin-left: 5px
}

.y-header_strip .y-header_form .y-form>div.col-lg-4>div {
	position: relative;
	padding-left: 35px
}

.y-header_strip .y-header_form .y-form>div.col-lg-4>div input,
.y-header_strip .y-header_form .y-form>div.col-lg-4>div span {
	margin-bottom: 10px
}

.y-header_strip .y-header_form .y-form>div.col-lg-4>div i {
	position: absolute;
	z-index: 999;
	left: 0;
	font-size: 26px;
	color: #06062a;
	top: 7px
}

.y-header_top_info {
	padding: 3px 0 0
}

.y-lang_icons,
.y-login_but {
	padding-left: 0
}

.y-lang_icons li,
.y-login_but li {
	display: inline-block
}

.y-lang_icons img,
.y-login_but img {
	width: 16px
}

.y-lang_icons a,
.y-login_but a {
	color: #06062a;
	font-size: 12px;
	font-weight: 400
}

.y-lang_icons li {
	margin-right: 10px;
	padding-top: 5px
}

.y-login_but a:after {
	content: "|";
	margin: 0 10px
}

.y-login_but li:last-child a:after {
	display: none
}

.y-login_but i {
	position: relative;
	top: 5px;
	font-size: 18px
}

.y-sticky {
	background: #fff!important;
	box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
	-moz-box-shadow: 0 0 5px #ccc
}

.y-sticky address {
	margin-bottom: 5px
}

.y-sticky address i {
	font-size: 18px
}

.y-sticky address a {
	font-size: 14px
}

.y-sticky .y-logo {
	margin-top: 5px
}

.y-sticky .y-logo img {
	max-height: 60px
}

.y-sticky nav {
	margin-top: 5px
}

.y-sticky .flexnav li a {
	padding: 3px .96em
}

.y-sticky .flexnav .touch-button {
	top: -14px
}

.y-sticky .rmm.style {
	padding: 0
}

.y-sticky .y-social {
	top: 8px
}

.y-sticky .y-menu_outer {
	margin-top: 0;
	top: 10px
}

@media (min-width:1024px) {
	.y-header_outer address {
		padding-top: 35px;
		margin: auto
	}
}

@media (min-width:768px) {
	.y-header_outer nav ul,
	.y-header_strip nav ul {
		display: inline-block;
		width: auto;
		max-height: none
	}
	.y-header_strip .header {
		position: fixed;
		z-index: 9999
	}
}

@media (max-width:1200px) {
	form.y-form .y-select_33>span {
		width: 32.3%
	}
	form.y-form .y-select_33>span select {
		width: 100%
	}
	.y-logo_bg {
		border-left: 25vw solid transparent;
		border-right: 25vw solid transparent
	}
}

@media (max-width:990px) {
	form.y-form .y-select_33>span {
		width: 32%
	}
	.select-wrapper .holder {
		margin-right: 0
	}
	form.y-form .y-select_half>span {
		width: 48.5%
	}
	form.y-form button {
		font-size: 14px;
		padding: 10px
	}
	form.y-form .y-bots_types img {
		max-width: 65px
	}
	.y-logo_bg {
		border-left: 30vw solid transparent;
		border-right: 30vw solid transparent;
		border-top: 20vh solid #fff
	}
}

@media (max-width:767px) {
	.y-header_form {
		margin: auto
	}
	.y-form .col-sm-5 {
		display: none
	}
	form.y-form .y-select_33>span {
		width: 32.3%
	}
	form.y-form .y-select_half>span {
		width: 49.1%
	}
	.y-logo_bg {
		border-left: medium none;
		border-right: medium none;
		border-top: medium none
	}
	.y-logo_bg img {
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0
	}
	.y-header_form form.y-form input {
		margin-bottom: 0
	}
	.y-header_strip .y-header_form .y-form>div.col-lg-3>div {
		margin-bottom: 10px
	}
	.y-footer .y-special_offer ul li .y-header_strip .y-header_form .y-read_more,
	.y-header_strip .y-header_form .y-button,
	.y-header_strip .y-header_form .y-footer .y-special_offer ul li .y-read_more,
	.y-header_strip .y-header_form .y-home_blog_content .y-btn,
	.y-home_blog_content .y-header_strip .y-header_form .y-btn {
		display: block;
		width: 100%
	}
	.y-header_strip #y-header_form,
	.y-header_strip .header {
		position: static
	}
	.parallax-image-wrapper-100 {
		display: none
	}
}

.y-our_services .y-service_inner {
	position: relative;
	overflow: hidden
}

.y-our_services .y-servie_info {
	background: rgba(255, 255, 255, .8) none repeat scroll 0 0;
	position: absolute;
	top: 77%;
	width: 100%;
	height: 100%
}

.y-our_services .y-servie_info h4 {
	margin: 0;
	font-size: 18px
}

.y-our_services .y-servie_info h4 a {
	text-transform: uppercase;
	color: #000;
	font-weight: 400;
	display: block;
	text-align: center;
	padding: 20px 10px
}

.y-our_services .y-servie_info p {
	padding: 0 20px;
	text-align: center;
	color: #fff
}

.y-our_services .y-service_inner:hover .y-servie_info {
	background: #06062a;
	top: 0
}

.y-our_services .y-service_inner:hover a {
	color: #fff
}

.y-section_inner {
	padding-top: 40px;
	padding-bottom: 40px
}

.y-section_inner .header {
	margin-bottom: 50px
}

.y-section_inner h2 {
	letter-spacing: .03em;
	margin-bottom: .3em;
	text-transform: uppercase;
	font-weight: 700
}

.y-section_content h1 {
	text-transform: uppercase;
	color: #003d70;
	padding-bottom: 15px;
	margin-bottom: 30px;
	position: relative
}

.y-section_content h1::before {
	background: #ccc none repeat scroll 0 0;
	border-radius: 50%;
	bottom: -1px;
	content: "";
	display: block;
	height: 5px;
	left: 116px;
	position: absolute;
	width: 5px;
	z-index: 999
}

.y-section_content h1::after {
	border-top: 1px solid #ccc;
	bottom: 0;
	content: "";
	display: block;
	height: 2px;
	left: 0;
	position: absolute;
	width: 120px
}

.y-section_content h5 {
	color: #0f0d0d;
	line-height: 28px;
	margin-top: 0
}

.y-contact_side>div {
	background-color: #f0f0f0;
	padding-bottom: 25px;
	padding: 25px 20px
}

.y-contact_side h3 {
	font-size: 18px;
	font-weight: 600;
	text-transform: uppercase
}

.y-contact_side hr {
	margin: 0 0 15px 0;
	border-color: #06062a;
	border-width: 2px
}

.y-contact_side form.y-form button {
	border: 1px solid #06062a;
	color: #06062a
}

.y-contact_side form.y-form button:hover {
	color: #fff;
	border-color: #3aa6f5
}

.y-popular_destination .header .fa {
	background: 0 0
}

.y-popular_destination h3 {
	font-weight: 300;
	position: relative;
	border-bottom: none
}

.y-popular_destination h3 strong {
	text-transform: uppercase
}

.y-popular_destination .y-boat_center {
	margin: 30px auto 80px
}

.camera_next>span:hover::before,
.camera_prev>span:hover::before {
	color: #06062a
}

.y-dest_list_single {
	margin-bottom: 7rem
}

.y-dest_list_single a {
	display: block
}

.y-dest_list_single h2 {
	margin-bottom: 10px;
	font-size: 34px
}

.y-dest_list_single h4 {
	margin-bottom: 0
}

.y-dest_list_single h2:hover {
	border-color: #06062a
}

.y-dest_list_single .pull-right.col-lg-5 img {
	float: right
}

.y-dest_list_single ul {
	margin: 20px 0;
	padding-left: 18px;
	list-style: square
}

.y-dest_list_single li {
	line-height: 32px
}

.y-dest_list_single .y-button,
.y-dest_list_single .y-footer .y-special_offer ul li .y-read_more,
.y-dest_list_single .y-home_blog_content .y-btn,
.y-footer .y-special_offer ul li .y-dest_list_single .y-read_more,
.y-home_blog_content .y-dest_list_single .y-btn {
	display: inline-block;
	padding: 8px 10px;
	background: #3aa6f5;
	border-color: #06062a;
	color: #fff
}

.y-dest_list_single .y-button:hover,
.y-dest_list_single .y-footer .y-special_offer ul li .y-read_more:hover,
.y-dest_list_single .y-home_blog_content .y-btn:hover,
.y-footer .y-special_offer ul li .y-dest_list_single .y-read_more:hover,
.y-home_blog_content .y-dest_list_single .y-btn:hover {
	color: #fff
}

.y-dest_list>div:last-child {
	margin-bottom: 0
}

.y-more_dest h3::after {
	background: #fff;
	width: 20%
}

.y-more_dest p {
	color: #fff
}

.y-more_dest img {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg)
}

.y-boat_type .header .fa {
	background: #0186d5
}

.y-boat_type .y-boat_selection {
	margin-top: 50px;
	text-align: center
}

.y-boat_type .col-xs-12.y-boat_selection>div {
	display: inline-block
}

.y-boat_type .col-xs-12.y-boat_selection>div h3 {
	font-weight: 400;
	font-size: 28px;
	color: #fff
}

.y-boat_type .y-boat_imgs img {
	display: inline-block;
	vertical-align: bottom;
	margin: 0 15px
}

.y-boat_type .y-select_boat {
	min-width: 240px;
	margin-right: 50px
}

.y-boat_type .y-select_boat h3 {
	border-bottom: 5px solid #fff;
	padding-bottom: 10px
}

.y-boat_type .y-select_boat p {
	color: #fff
}

.y-boat_type .y-select_boat p a {
	font-weight: 600;
	font-style: italic;
	color: #fff
}

.y-boat_type .y-boat_info {
	background-color: #fff;
	padding-bottom: 10px;
	padding-top: 10px;
	width: 100%
}

.y-boat_type .y-boat_info p {
	color: #000;
	margin: 0
}

.y-boat_type .y-boat_info a {
	color: #000;
	font-size: 14px
}

.y-boat_type .y-boat_info span {
	position: absolute;
	right: 15px;
	top: 5px;
	color: #003d70;
	font-weight: 60;
	font-style: italic;
	font-size: 24px
}

.y-boat_type .y-boat_carousel {
	clear: both;
	padding-top: 60px
}

.y-boat_type .owl-controls {
	position: relative
}

.y-boat_type .owl-controls .owl-nav:after {
	clear: both;
	display: block;
	content: ""
}

.y-boat_type .y-section_inner {
	overflow: hidden
}

.y-boat_type .owl-nav {
	text-align: center
}

.y-boat_type .fa-anchor {
	font-size: 34px
}

.y-boat_type .y-right {
	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg)
}

.y-boat_type .y-left {
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg)
}

.y-boat_type .fa-anchor:hover {
	color: #06062a
}

.y-boat_type .owl-theme .owl-controls .owl-nav [class*=owl-] {
	position: relative;
	z-index: 99
}

.y-boat_type .owl-controls::after {
	background: #ccc none repeat scroll 0 0;
	bottom: 0;
	content: "";
	display: block;
	height: 1px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 99%
}

.y-blog_listing img,
.y-feature_post img.img-fluid {
	border: 10px solid #fff;
	box-shadow: 0 0 3px #d0d0d0;
	-moz-box-shadow: 0 0 3px #d0d0d0;
	-webkit-box-shadow: 0 0 3px #d0d0d0
}

.y-blogs .y-feature_post {
	width: 100%;
	margin-top: 50px
}

.y-blogs .y-feature_post .y-blog_info h5 {
	margin-top: 30px
}

.y-blogs .y-feature_post .y-blog_info h3 {
	color: #06062a;
	position: relative
}

.y-blogs .y-feature_post .y-blog_info p {
	font-size: 14px
}

.y-blogs .y-feature_post .y-blog_info .y-footer .y-special_offer ul li a.y-read_more,
.y-blogs .y-feature_post .y-blog_info .y-home_blog_content a.y-btn,
.y-blogs .y-feature_post .y-blog_info a.y-button,
.y-footer .y-special_offer ul li .y-blogs .y-feature_post .y-blog_info a.y-read_more,
.y-home_blog_content .y-blogs .y-feature_post .y-blog_info a.y-btn {
	text-align: center;
	background: #3aa6f5;
	color: #fff;
	padding: 8px 10px;
	margin-bottom: 15px
}

.y-blogs .y-feature_post .y-blog_info .y-footer .y-special_offer ul li a.y-read_more:hover,
.y-blogs .y-feature_post .y-blog_info .y-home_blog_content a.y-btn:hover,
.y-blogs .y-feature_post .y-blog_info a.y-button:hover,
.y-footer .y-special_offer ul li .y-blogs .y-feature_post .y-blog_info a.y-read_more:hover,
.y-home_blog_content .y-blogs .y-feature_post .y-blog_info a.y-btn:hover {
	color: #fff
}

.y-blogs .y-feature_post .y-blog_info .y-blog_general_info li a,
.y-blogs .y-feature_post .y-blog_info .y-blog_general_info li span {
	text-transform: capitalize
}

.y-blogs .y-feature_post .y-blog_info .y-blog_general_info li i {
	top: 5px
}

.y-blogs .y-info_pop {
	background-color: #06062a;
	max-width: 200px;
	padding: 10px 15px;
	position: absolute;
	right: 50px;
	text-align: left;
	top: 60px;
	width: 100%
}

.y-blogs .y-info_pop i,
.y-blogs .y-info_pop span {
	font-size: 13px;
	color: #fff
}

.y-blogs .y-info_pop i {
	top: 2px;
	position: relative;
	margin-right: 5px
}

.y-blogs .y-info_pop a {
	margin: auto;
	color: #fff;
	font-size: 14px;
	display: block;
	line-height: 20px;
	font-weight: 600
}

.y-blogs .y-info_pop a:hover {
	color: #3aa6f5
}

.y-blogs .y-blog_listing {
	margin-top: 60px
}

@media (max-width:1200px) {
	.y-section_content {
		padding: 0
	}
	form.y-form .y-select_half input {
		width: 49%
	}
	.y-boat_type .y-boat_info span {
		right: 5px
	}
}

@media (max-width:990px) {
	.y-section_content h2 br,
	.y-section_content h4 br,
	.y-section_content p br {
		display: none
	}
	.y-blog_listing {
		margin: auto
	}
	.y-blogs .y-info_pop {
		left: 5px
	}
	.y-boat_type .y-boat_imgs img {
		max-width: 80px;
		margin: 0 10px
	}
}

@media (max-width:767px) {
	.y-dest_list_single img {
		margin: auto
	}
	.y-dest_list_single h2 {
		padding-top: 15px;
		clear: both
	}
	.y-blog_listing div.y-info_pop {
		left: 0;
		right: 0;
		margin: auto;
		background: rgba(0, 0, 0, .8) none repeat scroll 0 0
	}
	.y-blog_listing img {
		margin: auto;
		margin-bottom: 25px
	}
	.y-popular_destination h3 {
		margin-top: 20px
	}
	.y-boat_carousel {
		max-width: 300px;
		margin: auto
	}
	.y-feature_post img {
		margin: auto
	}
	.y-boat_type .owl-controls::after {
		width: 85%
	}
	.y-our_services .y-service_inner {
		max-width: 324px;
		margin: auto auto 20px
	}
	.y-dest_list_single li {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 10px
	}
	.y-blogs .y-feature_post {
		margin-left: auto;
		margin-right: auto
	}
	.y-boat_type .y-boat_imgs img {
		margin: 10px
	}
}

.y-footer {
	background: #fff;
	padding: 60px 0 0
}

.y-footer h5 {
	color: #000;
	text-transform: capitalize;
	padding-bottom: 10px
}

.y-footer h5 i,
.y-footer h5 span {
	float: left;
	margin-right: 10px
}

.y-footer h5 span {
	font-size: 18px
}

.y-footer h5:after {
	clear: both;
	display: block;
	content: ""
}

.y-footer a,
.y-footer p,
.y-footer span {
	color: #464646;
	font-size: 13px
}

.y-footer ul {
	list-style: square;
	padding-left: 23px
}

.y-footer ul li {
	line-height: 34px
}

.y-footer ul li strong {
	font-weight: 600;
	font-size: 13px;
	color: #303030
}

.y-footer hr {
	border-color: #d6d6d6
}

.y-footer p.text-center {
	margin-bottom: 0
}

.y-footer a:hover {
	color: #06062a
}

.y-footer .y-special_offer ul {
	list-style: none;
	padding-left: 5px
}

.y-footer .y-special_offer ul li {
	position: relative
}

.y-footer .y-special_offer ul li a {
	font-weight: 600;
	color: #303030
}

.y-footer .y-special_offer ul li p {
	margin-top: 0;
	font-size: 13px
}

.y-footer .y-special_offer ul li .y-read_more {
	font-size: 14px;
	padding: 0 10px
}

.y-footer .y-social_links {
	text-align: right
}

.y-footer .y-social_links p {
	margin-top: 5px
}

.y-footer .y-social_links a {
	font-size: 18px;
	display: inline-block;
	margin-left: 5px
}

.y-footer p.text-left {
	margin: auto
}

.y-footer .y-footer_strip {
	background: #fff;
	padding: 8px 0;
	margin-top: 50px;
	box-shadow: 0 0 5px #ccc;
	-moz-box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc
}

.y-footer .y-footer_strip p {
	margin: auto;
	color: #06062a
}

.y-footer .y-footer_strip .fa {
	color: #06062a
}

.y-footer .y-form button {
	background: #e1e1e1 none repeat scroll 0 0;
	color: #303030;
	font-size: 14px;
	font-weight: 700;
	margin-top: 5px;
	max-width: 100px;
	padding: 10px;
	text-transform: uppercase
}

.y-footer .y-form button:hover {
	color: #fff
}

.y-footer .y-social a {
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	width: 40px
}

.y-footer .y-social a i {
	color: #fff
}

.y-footer form.y-form button {
	border: none;
	border-left: 5px solid #06062a
}

@media (max-width:990px) {
	.y-footer .col-lg-3 {
		margin-bottom: 50px
	}
}

@media (max-width:767px) {
	.y-footer h5 {
		margin-bottom: 10px;
		padding-bottom: 0
	}
	.y-footer .text-left,
	.y-footer .y-social_links {
		text-align: center
	}
	.y-footer .y-footer_strip p {
		margin: 8px auto
	}
}

.y-client_says_section {
}

.y-client_says_section .owl-stage-outer {
	margin-top: 50px
}

.y-client_says_section h2 {
	color: #fff
}

.y-tab_review .y-client_testimonials {
	color: #464646;
	padding: 15px 15px 15px 130px;
	position: relative;
	text-align: left
}

.y-tab_review .y-client_testimonials>img {
	left: 10px;
	max-height: 100px;
	position: absolute;
	top: 22px
}

.y-tab_review .y-client_testimonials {
	color: #464646;
	padding: 15px 15px 15px 130px;
	position: relative;
	text-align: left
}

.y-tab_review .y-client_testimonials p {
	max-width: 100%;
	margin: auto auto 0
}

.y-client_testimonials {
	text-align: center;
	color: #fff
}

.y-client_testimonials p {
	max-width: 80%;
	margin: auto auto 30px
}

.y-header_01 .header {
	padding: 10px 0 15px
}

.y-header_01 .header .fa {
	color: #06062a
}

.y-header_01 .header .fa:hover {
	color: #fff
}

.y-header_01 .header .rmm.style ul li>a:not(:only-child)::before {
	top: 17px;
	right: 3px
}

.y-header_01 .y-header_outer nav {
	border: none;
	margin-top: 35px
}

.y-header_01 address {
	text-align: right;
	margin-bottom: 0
}

.y-header_01 .y-menu_outer {
	margin-top: 0;
	border-top: 0
}

.y-header_01 .flexnav li a {
	padding: .96em .36em
}

.y-header_01 .rmm.style ul li a {
	font-size: 13px;
	margin-right: 0;
	padding: 1.2em 1.5em
}

.y-header_01 .rmm.style ul ul li a {
	padding: 6px 50px 6px 15px;
	margin-right: 0
}

.y-header_01 .y-sticky .rmm.style ul li>a:not(:only-child)::before {
	top: 18px
}

.y-header_01 .y-top_info_part>* {
	display: inline-block;
	margin-left: 10px;
	padding-top: 0!important;
	text-align: right;
	position: static
}

.y-header_01 .y-top_info_part {
	text-align: right
}

.y-header_01 .y-top_info_part address {
	margin-bottom: 0;
	text-align: right
}

.y-header_01 .rmm-toggled {
	bottom: auto;
	top: auto;
	position: static
}

.y-header_01 .rmm-toggled-button {
	float: right;
	margin: auto
}

.y-header_01 .rmm.style ul.rmm-mobile {
	background: #fff none repeat scroll 0 0;
	position: absolute;
	left: 0;
	margin-top: 18px;
	z-index: 9999
}

.y-header_01 .rmm.style>ul>li>a {
	color: #06062a
}

.y-sticky .y-header_01 .header {
	padding: 0
}

.y-sticky .y-header_01 .flexnav li a {
	margin-right: 10px;
	padding: .96em .7em .96em 0
}

@media (min-width:1024px) {
	.y-header_01 .y-menu_outer .rmm.style {
		float: right
	}
}

@media (max-width:767px) {
	.y-header_01 {
	}
	.y-header_01 .y-logo img {
		margin: auto;
		max-height: 80px
	}
	.y-header_01 .col-lg-8 {
		padding-top: 0
	}
	.y-header_01 .y-header_outer .row>.col-sm-2 {
		text-align: center;
		margin-bottom: 15px
	}
	.y-header_01 .rmm-toggled,
	.y-header_01 .y-header_outer .y-social {
		position: static
	}
	.y-header_01 .y-top_info_part {
		text-align: center
	}
	.y-header_01 .rmm.style ul {
		margin-top: 0
	}
}

.y-home_02 .y-slider_form {
	position: relative
}

.y-home_02 .y-slider_form>div#y-header_form {
	position: absolute;
	top: 0;
	right: 10%
}

.y-home_02 .y-header_form {
	background: rgba(255, 255, 255, .8) none repeat scroll 0 0;
	max-width: 400px;
	padding: 30px;
	position: absolute;
	right: 0;
	top: 100px;
	z-index: 999
}

.y-home_02 .y-header_form h3 {
	margin-bottom: 30px;
	color: #06062a;
	font-size: 24px
}

.y-home_02 .y-header_form div.col-lg-12 button {
	color: #06062a;
	border-color: #06062a
}

.y-home_02 .y-header_form div.col-lg-12 button i {
	position: relative;
	top: 5px;
	margin-right: 8px
}

.y-home_02 .y-header_form div.col-lg-12 button:hover {
	color: #fff
}

.y-home_02 .y-header_form div.col-lg-12>div {
	padding-left: 35px;
	position: relative;
	margin-bottom: 15px
}

.y-home_02 .y-header_form div.col-lg-12>div input {
	margin-bottom: 0
}

.y-home_02 .y-header_form div.col-lg-12>div i {
	color: #003d70;
	font-size: 26px;
	left: 0;
	position: absolute;
	top: 7px;
	z-index: 999
}

.y-home_02 .y-book_today .y-section_inner {
	padding-top: 10px
}

.y-home_02 .y-our_services {
	text-align: center
}

.y-home_02 .y-our_services i {
	font-size: 34px
}

.y-home_02 .y-our_services .y-servie_info {
	background: 0 0!important;
	position: static;
	margin-bottom: 25px
}

.y-home_02 .y-our_services .y-servie_info span {
	text-transform: uppercase;
	color: #06062a;
	font-size: 16px;
	display: block;
	margin: 20px auto 15px
}

.y-home_02 .y-our_services .y-servie_info p {
	margin: auto;
	color: #464646
}

.y-header_05 .header {
	padding: 0 0 5px;
	background: #fff
}

.y-header_05 .header .fa {
	color: #06062a
}

.y-header_05 .header .fa:hover {
	color: #fff
}

.y-header_05 .header .rmm.style ul li>a:not(:only-child)::before {
	top: 17px;
	right: 3px
}

.y-header_05 .y-header_strip .y-logo {
	margin-top: 0
}

.y-header_05 .y-header_outer nav {
	border: none;
	margin-top: 35px
}

.y-header_05 address {
	text-align: right;
	margin-bottom: 0
}

.y-header_05 .y-menu_outer {
	margin-top: 0;
	border-top: 0
}

.y-header_05 .flexnav li a {
	padding: .96em .36em
}

.y-header_05 .rmm.style ul li a {
	font-size: 13px;
	margin-right: 0;
	padding: 1.2em 1.5em
}

.y-header_05 .rmm.style ul ul li a {
	padding: 6px 50px 6px 15px;
	margin-right: 0
}

.y-header_05 .y-sticky .rmm.style ul li>a:not(:only-child)::before {
	top: 18px
}

.y-header_05 .y-top_info_part>* {
	display: inline-block;
	margin-left: 10px;
	padding-top: 0!important;
	text-align: right;
	position: static
}

.y-header_05 .y-top_info_part {
	text-align: right
}

.y-header_05 .y-top_info_part address {
	margin-bottom: 0;
	text-align: right
}

.y-header_05 .rmm-toggled {
	bottom: auto;
	top: auto;
	position: static
}

.y-header_05 .rmm-toggled-button {
	float: right;
	margin: auto
}

.y-header_05 .rmm.style ul.rmm-mobile {
	background: #fff none repeat scroll 0 0;
	position: absolute;
	left: 0;
	margin-top: 18px;
	z-index: 9999
}

.y-header_05 .rmm.style>ul>li>a {
	color: #06062a
}

.y-product_home {
	padding-top: 50px;
	clear: both
}

.y-sticky .y-header_05 .header {
	padding: 0
}

.y-sticky .y-header_05 .flexnav li a {
	margin-right: 10px;
	padding: .96em .7em .96em 0
}

@media (min-width:1024px) {
	.y-header_05 .y-menu_outer .rmm.style {
		float: right
	}
}

@media (max-width:767px) {
	.y-header_05 {
	}
	.y-header_05 .y-logo img {
		margin: auto;
		max-height: 80px
	}
	.y-header_05 .col-lg-8 {
		padding-top: 0
	}
	.y-header_05 .y-header_outer .row>.col-sm-2 {
		text-align: center;
		margin-bottom: 15px
	}
	.y-header_05 .rmm-toggled,
	.y-header_05 .y-header_outer .y-social {
		position: static
	}
	.y-header_05 .y-top_info_part {
		text-align: center
	}
	.y-header_05 .rmm.style ul {
		margin-top: 0
	}
}

.y-header_06 .header {
	padding: 0 0 5px;
	background: 0 0
}

.y-header_06 .header .fa {
	color: #06062a
}

.y-header_06 .header .fa:hover {
	color: #fff
}

.y-header_06 .header .rmm.style ul li>a:not(:only-child)::before {
	top: 17px;
	right: 3px
}

.y-header_06 .y-header_strip .y-logo {
	margin-top: 0
}

.y-header_06 .y-header_outer nav {
	border: none;
	margin-top: 35px
}

.y-header_06 address {
	text-align: right;
	margin-bottom: 0
}

.y-header_06 .y-menu_outer {
	margin-top: 0;
	border-top: 0
}

.y-header_06 .flexnav li a {
	padding: .96em .36em
}

.y-header_06 .rmm.style ul li a {
	font-size: 13px;
	margin-right: 0;
	padding: 1.2em 1.5em
}

.y-header_06 .rmm.style ul ul li a {
	padding: 6px 50px 6px 15px;
	margin-right: 0
}

.y-header_06 .y-sticky .rmm.style ul li>a:not(:only-child)::before {
	top: 18px
}

.y-header_06 .y-top_info_part>* {
	display: inline-block;
	margin-left: 10px;
	padding-top: 0!important;
	text-align: right;
	position: static
}

.y-header_06 .y-top_info_part {
	text-align: right
}

.y-header_06 .y-top_info_part address {
	margin-bottom: 0;
	text-align: right
}

.y-header_06 .rmm-toggled {
	bottom: auto;
	top: auto;
	position: static
}

.y-header_06 .rmm-toggled-button {
	float: right;
	margin: auto
}

.y-header_06 .rmm.style ul.rmm-mobile {
	background: #fff none repeat scroll 0 0;
	position: absolute;
	left: 0;
	margin-top: 18px;
	z-index: 9999
}

.y-header_06 .rmm.style>ul>li>a {
	color: #06062a
}

.y-product_home {
	padding-top: 50px;
	clear: both
}

.y-sticky .y-header_06 .header {
	padding: 0
}

.y-sticky .y-header_06 .flexnav li a {
	margin-right: 10px;
	padding: .96em .7em .96em 0
}

@media (min-width:1024px) {
	.y-header_06 .y-menu_outer .rmm.style {
		float: right
	}
}

@media (max-width:767px) {
	.y-header_06 {
	}
	.y-header_06 .y-logo img {
		margin: auto;
		max-height: 80px
	}
	.y-header_06 .col-lg-8 {
		padding-top: 0
	}
	.y-header_06 .y-header_outer .row>.col-sm-2 {
		text-align: center;
		margin-bottom: 15px
	}
	.y-header_06 .rmm-toggled,
	.y-header_06 .y-header_outer .y-social {
		position: static
	}
	.y-header_06 .y-top_info_part {
		text-align: center
	}
	.y-header_06 .rmm.style ul {
		margin-top: 0
	}
}

.y-inner_header {
	padding-top: 15px;
	padding-bottom: 200px
}

.y-inner_header .header {
	margin-top: -15px
}

.y-about_us {
}

.y-cat_full {
}

.y-single_charter {
}

.y-sale {
}

.y-product {
}

.y-dest_listing {
}

.y-single_dest {
}

.y-post_list {
}

.y-post_single {
}

.y-landing>.y-inner_header {
	background-size: 100%;
	position: relative;
	padding-bottom: 375px
}

.y-contact_one {
}

.y-contact_two {
}

.y-contact_three {
}

.y-single_info_inner {
	background-color: #fff
}

.y-single_info_inner .col-sm-8 {
	padding-right: 30px
}

.y-single_info_inner ul {
	list-style: outside none square;
	margin: 0;
	padding-left: 18px
}

.y-single_info_inner li {
	line-height: 32px
}

.y-single_info_inner li>span {
	text-transform: capitalize
}

.y-single_info_inner li>span.y-info_val {
	float: right
}

.y-single_info_inner .y-dest_list h3 {
	color: #06062a
}

.y-single_info_inner .y-dest_list h3 a {
	color: #06062a
}

.y-single_info_inner .y-dest_list h3 {
	margin-bottom: 20px
}

.y-single_info_inner .y-dest_list ul {
	margin: 15px 0 20px
}

.y-single_info_inner .y-dest_list h4,
.y-single_info_inner .y-dest_list li {
	color: #464646
}

@media (min-width:1200px) {
	.y-landing>.y-inner_header {
		padding-bottom: 500px
	}
}

@media (min-width:1400px) {
	.y-landing>.y-inner_header {
		padding-bottom: 600px
	}
}

.responsive-tabs-wrapper .y-yacht_intro_img img {
	top: -5px
}

.responsive-tabs-wrapper .y-yacht_intro_img {
	height: 250px
}

.y-slideshow {
	padding: 7rem 0 4rem
}

.y-footer {
	box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
	-moz-box-shadow: 0 0 5px #ccc;
	position: relative
}

.y-inner_page .y-sidebar {
	background: #000c1e;
	padding: 30px 15px
}

.y-inner_page .y-sidebar h3 {
	color: #fff;
	font-size: 22px;
	margin-bottom: 30px;
	font-weight: 700;
	text-transform: uppercase
}

.y-inner_page .y-sidebar li a {
	color: #e3e3e3;
	margin-right: 5px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600
}

.y-inner_page .y-sidebar li a:hover {
	color: #3aa6f5
}

.y-inner_page .y-sidebar .y-side_posts_text>a:hover {
	color: #3aa6f5
}

.y-side_bar_single {
	margin-bottom: 45px
}

.y-sidebar h4 {
	color: #000;
	font: 700 15px/18px;
	text-transform: uppercase;
	margin: auto auto 20px
}

.y-side_search input {
	background: 0 0;
	border: 1px solid #6380aa;
	color: #fff;
	font: 400 16px/18px;
	max-width: 100%;
	padding: 15px;
	width: 100%
}

.y-side_bar_cat_list {
	padding-left: 10px
}

.y-side_bar_cat_list li {
	padding-bottom: 10px
}

.y-side_bar_cat_list li a {
	font: 400 13px/20px;
	color: #696969
}

.y-side_bar_cat_list li span {
	float: right
}

.y-side_bar_cat_list li:last-child {
	padding-bottom: 0
}

.y-side_text p {
	font: 400 13px/22px;
	color: #696969
}

.y-side_tags_list {
	padding: 0
}

.y-side_tags_list li {
	display: inline-block;
	margin-bottom: 5px;
	margin-right: 3px;
	background: #ebebeb
}

.y-side_tags_list li a {
	color: #202020;
	display: block;
	font: 400 14px/1;
	padding: 8px 12px
}

.y-side_tags_list li:hover a {
	color: #fff
}

.y-side_posts_list>div {
	margin-bottom: 20px;
	position: relative;
	padding-left: 100px;
	padding-top: 0;
	min-height: 80px
}

.y-side_posts_list>div img {
	position: absolute;
	top: 0;
	left: 0
}

.y-side_posts_list>div:last-child {
	margin-bottom: 0
}

.y-side_posts_text>a {
	color: #fff;
	font: 700 15px/18px;
	text-transform: uppercase;
	padding-bottom: 2px;
	display: inline-block
}

.y-side_posts_text ul {
	padding-left: 0
}

.y-single_tweet {
	font: 400 13px/22px;
	color: #696969
}

.y-single_tweet>p {
	line-height: 18px;
	margin: auto;
	font-size: 13px;
	display: inline
}

.y-blog_detail {
	margin: auto!important
}

.y-blog_detail li {
	padding-right: 10px
}

.y-blog_detail li:after {
	position: absolute;
	content: "|";
	top: 0;
	right: 2px;
	font-style: normal
}

.y-blog_detail li,
.y-blog_detail li a {
	display: inline-block;
	color: #e6e6e6;
	font: italic 12px/16px;
	position: relative
}

.y-blog_detail li:last-child:after {
	content: ""
}

.textwidget p {
	color: #fff
}

.tagcloud a {
	background: #6380aa none repeat scroll 0 0;
	color: #000c1e;
	display: inline-block;
	font: 400 14px/1!important;
	margin-bottom: 5px;
	margin-right: 1px;
	padding: 8px 12px
}

.wp-caption {
	max-width: 100%
}

.widget img,
.widget select {
	max-width: 100%;
	height: auto
}

.y-side_bar_single>ul {
	padding-left: 15px
}

.y-side_bar_single>ul>li {
	padding-bottom: 0;
	color: #6380aa
}

.widget_categories a {
	float: left
}

.menu ul {
	padding-left: 20px
}

.menu {
	padding-left: 10px
}

ul.menu>li {
	padding-bottom: 10px
}

table th {
	background: #f8f8f8 none repeat scroll 0 0;
	color: #000;
	font-size: 13px;
	font-weight: 700;
	padding: 7px;
	text-align: left;
	text-shadow: 0 1px 0 #fff
}

table td,
table th {
	border: 1px solid #dce3eb;
	vertical-align: top
}

table td {
	background: #fff none repeat scroll 0 0;
	color: #484848;
	padding: 5px
}

table {
	border: 1px solid #dce3eb;
	border-collapse: collapse;
	margin: 15px 0;
	width: 100%
}

.widget select {
	padding: 8px;
	width: 100%
}

.comment-form .submit {
	background: transparent none repeat scroll 0 0;
	display: block;
	font: 400 17px/18px;
	margin: 50px auto auto;
	padding: 15px 30px;
	text-transform: uppercase;
	width: 225px
}

.y-single_blog img {
	height: auto;
	max-width: 100%
}

.rssSummary {
	font-size: 14px;
	line-height: 24px
}

.tagcloud a:hover {
	background: #3aa6f5;
	color: #fff
}

.y-blog_detail_content {
	padding: 0
}

.y-blog_detail_bloger_img .y-glyp_icon span {
	display: block;
	padding: 20px 0 10px
}

.y-blog_detail_bloger_img .y-glyp_icon {
	background: #303030 none repeat scroll 0 0;
	color: #fff;
	fony-size: 22px
}

.y-blog_detail_info h2 {
	font-size: 28px;
	color: #06062a;
	font-weight: 600;
	word-spacing: 2px;
	margin-bottom: 0
}

.y-blog_general_info {
	padding-left: 0!important
}

.y-blog_general_info li {
	display: inline-block;
	position: relative;
	padding-left: 15px
}

.y-blog_general_info li i {
	position: absolute;
	left: 0;
	top: 8px;
	font-size: 18px
}

.y-blog_general_info li span {
	border-right: 1px solid #e5e5e5;
	color: #949494;
	font: italic 400 12px/1;
	padding: 0 10px
}

.y-blog_general_info li a {
	color: #949494
}

.y-blog_general_info li:last-child span {
	border-right: none
}

.y-blog_detail_info {
	margin-bottom: 20px;
	padding-bottom: 10px
}

.y-blog_detail_text {
	padding-bottom: 30px
}

.y-blog_detail_text p {
	text-align: left
}

.y-alpha_text {
	height: 60px;
	width: 60px;
	background: #202020;
	color: #fff;
	font: 400 28px/60px;
	text-align: center;
	display: block;
	margin: 8px 8px 0 0;
	float: left
}

.y-blog_detail_text>img {
	margin: 40px 0
}

.y-blog_detail_content .y-pagination {
	padding-top: 30px;
	padding-bottom: 50px
}

.y-blog_social_links ul li {
	display: inline-block
}

.y-blog_social_links ul li a {
	font-size: 14px;
	color: #202020;
	display: block;
	height: 35px;
	width: 35px;
	text-align: center;
	line-height: 35px
}

.y-blog_social_links ul li a:hover {
	background: #06062a;
	color: #fff
}

.y-home_blog_content ul {
	margin-bottom: 10px
}

.y-home_blog_content h3 {
	font-size: 24px;
	margin-top: 10px;
	font-weight: 600;
	margin-bottom: 0;
	padding-bottom: 10px;
	color: #06062a
}

.y-home_blog_content p {
	margin: 10px 0
}

.y-home_blog_content .y-btn {
	text-transform: capitalize;
	padding: 10px;
	border-color: #3aa6f5;
	background: #3aa6f5;
	color: #fff
}

.y-single_blog {
	margin-bottom: 50px
}

footer.post-meta {
	margin-bottom: 15px
}

footer.post-meta span {
	background-position: left 2px;
	background-repeat: no-repeat;
	background-size: auto 12px;
	color: #949494;
	display: inline-block
}

footer.post-meta span.post-categories {
	margin-left: 5px;
	padding-left: 16px
}

footer.post-meta span a {
	color: #949494;
	display: inline-block;
	font: 400 13px/1
}

@media (max-width:767px) {
	.y-blog_detail_bloger_img {
		display: none
	}
	.y-blog_detail_content {
		padding: 0 15px
	}
}

.y-product_listing>div {
	background: #fff;
	margin-bottom: 30px
}

.y-product_listing>div .m-prod_list_img {
	height: 260px;
	text-align: center;
	border-bottom: 1px solid;
	position: relative
}

.y-product_listing>div .m-prod_list_img img {
	bottom: 0;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0
}

.y-product_listing>div .m-prod_list_text {
	height: 165px;
	padding: 28px 15px 0;
	border-top: 1px solid transparent
}

.y-product_listing>div a.y-heading {
	display: inline-block;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 25px;
	color: #06062a
}

.y-product_listing>div .y-adv_info_foot {
	position: absolute;
	bottom: 15px;
	width: calc(100% - 30px)
}

.y-product_listing>div .y-adv_info_foot a {
	color: #06062a
}

.y-product_listing>div .y-fav_item {
	top: 10px;
	left: 10px;
	position: absolute
}

.y-yacht_intro_img {
	height: auto;
	text-align: center;
	border-bottom: 1px solid #efefef;
	position: relative;
	overflow: hidden
}

.y-yacht_intro_img a:hover img {
	transform: scale(1.25);
	-webkit-transform: scale(1.25);
	-moz-transform: scale(1.25)
}

.y-yacht_intro_img a {
	display: block;
	height: 100%;
	position: relative;
	width: 100%
}

.y-yacht_intro_img img {
	bottom: 0;
	left: 0;
	margin: auto;
	position: static;
	right: 0;
	top: 0
}

.y-yacht_intro {
	padding: 15px;
	background: #f7f7f7
}

.y-yacht_intro a:not(.button-fancy) {
	text-transform: uppercase;
	font-size: 17px;
	font-weight: 700;
	color: inherit;
	text-align: center;
	display: block
}

.y-yacht_intro>span {
	display: block;
	margin-bottom: 0;
	text-align: center;
	font-style: italic;
	color: #06062a
}

.y-yacht_intro ul {
	padding-left: 0;
	list-style: none;
	padding: 10px 0;
	margin: 10px 0 15px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	text-align: center
}

.y-yacht_intro ul li {
	display: inline-block;
	color: #606060;
	position: relative;
	font-size: 14px;
	font-weight: 200;
	text-transform: uppercase
}

.y-yacht_intro ul img {
	max-width: 20px;
	margin-right: 10px;
	position: relative;
	top: -2px
}

.y-yacht_intro ul i {
	position: relative;
	top: 3px;
	font-size: 20px
}

.y-yacht_intro ul li:first-child {
	margin-right: 20px;
	padding-right: 20px
}

.y-yacht_intro ul li:first-child:after {
	position: absolute;
	height: 100%;
	right: 0;
	top: 0;
	border-right: 1px solid #ddd;
	content: "";
	display: block
}

.y-footer .y-special_offer ul li .y-yacht_intro .y-read_more,
.y-home_blog_content .y-yacht_intro .y-btn,
.y-yacht_intro .y-button,
.y-yacht_intro .y-footer .y-special_offer ul li .y-read_more,
.y-yacht_intro .y-home_blog_content .y-btn {
	display: block;
	width: 100%;
	padding: 10px;
	text-align: center;
	background: #3aa6f5;
	color: #fff
}

.y-product_filters ul {
	padding: 0;
	list-style: none
}

.y-product_filters .y-single_filter {
	margin-bottom: 50px
}

.y-product_filters>div:last-child {
	margin-bottom: 0
}

.y-product_filters .y-single_filter>span {
	font-size: 14px;
	font-weight: 700;
	display: block;
	margin-bottom: 40px
}

.y-product_filters .y-single_filter li span {
	font: 16px;
	margin-bottom: 8px;
	display: block;
	color: #fff;
	cursor: pointer
}

.y-range_meter li {
	max-width: 48%;
	display: block;
	width: 100%;
	float: left
}

.y-range_meter li:nth-child(1) {
	margin-right: 2%
}

.y-range_meter input,
.y-range_meter span {
	width: 100%;
	padding: 5px 0;
	height: 25px;
	color: #212121
}

.y-range_meter input {
	background: 0 0;
	color: #fff;
	border: none;
	text-align: right
}

.y-login_popup [type=checkbox]:checked,
.y-login_popup [type=checkbox]:not(:checked),
.y-product_filters [type=checkbox]:checked,
.y-product_filters [type=checkbox]:not(:checked) {
	position: absolute;
	left: -9999px
}

.y-login_popup [type=checkbox]:checked+label,
.y-login_popup [type=checkbox]:not(:checked)+label,
.y-product_filters [type=checkbox]:checked+label,
.y-product_filters [type=checkbox]:not(:checked)+label {
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	display: block;
	font: 400 14px;
	margin-bottom: 10px;
	color: #e3e3e3;
	cursor: pointer
}

.y-login_popup [type=checkbox]:checked+label::before,
.y-login_popup [type=checkbox]:not(:checked)+label::before,
.y-product_filters [type=checkbox]:checked+label::before,
.y-product_filters [type=checkbox]:not(:checked)+label::before {
	border: 1px solid #e3e3e3;
	border-radius: 0;
	content: "";
	height: 17px;
	left: 0;
	position: absolute;
	top: 7px;
	width: 17px
}

/*.y-login_popup [type=checkbox]:checked+label::after,
.y-login_popup [type=checkbox]:not(:checked)+label::after,
.y-product_filters [type=checkbox]:checked+label::after,
.y-product_filters [type=checkbox]:not(:checked)+label::after {
	background: #000 none repeat scroll 0 0;
	color: #fff;
	content: "\f00c";
	font-family: fontawesome;
	font-size: 13px;
	height: 15px;
	left: 1px;
	line-height: .8;
	padding-left: 1px;
	padding-top: 2px;
	position: absolute;
	top: 8px;
	transition: all .2s ease 0s;
	width: 15px
}*/
.y-login_popup [type=checkbox]:checked+label::after,
.y-login_popup [type=checkbox]:not(:checked)+label::after,
.y-product_filters [type=checkbox]:checked+label::after,
.y-product_filters [type=checkbox]:not(:checked)+label::after  {

}
.y-login_popup [type=checkbox]:checked+label::after,
.y-login_popup [type=checkbox]:not(:checked)+label::after,
.y-product_filters [type=checkbox]:checked+label::after,
.y-product_filters [type=checkbox]:not(:checked)+label::after  {
	content: "";
	position: absolute;
	left: 5px;
	top: 8px;
	width: 7px;
	height: 12px;
	border: solid white;
	border-width: 0 3px 3px 0;
	opacity: 0;
}


.y-login_popup [type=checkbox]:checked+label:after,
.y-product_filters [type=checkbox]:checked+label:after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 1;
}

/*.y-login_popup [type=checkbox]:not(:checked)+label:after,
.y-product_filters [type=checkbox]:not(:checked)+label:after {
	opacity: 0;
	transform: scale(0)
}

.y-login_popup [type=checkbox]:checked+label:after,
.y-product_filters [type=checkbox]:checked+label:after {
	opacity: 1;
	transform: scale(1)
}*/

.y-nav_but {
	background: #fff none repeat scroll 0 0;
	box-shadow: 0 2px 2px #bfbfbf;
	color: #424242;
	display: inline-block;
	font: 16px;
	padding: 12px 15px;
	text-transform: uppercase
}

.y-pagination {
	padding-top: 0;
	clear: both
}

.y-pagination ul {
	list-style: none;
	display: inline-block;
	padding: 0
}

.y-pagination span {
	display: inline-block
}

.y-pagination li {
	display: inline-block
}

.y-pagination ul.y-pagination_num li {
	height: 48px;
	width: 48px;
	background: #fff;
	text-align: center;
	float: left
}

.y-pagination ul.y-pagination_num li a {
	font: 16px;
	color: #212121;
	display: block;
	line-height: 48px
}

.y-pagination ul.y-pagination_num a:hover,
.y-pagination ul.y-pagination_num li a.y-active_page {
	background: #fafafa;
	color: #9e9e9e
}

.y-pagination .y-pagination_count {
	text-align: right;
	line-height: 48px
}

.y-pagination .y-pagination_count a,
.y-pagination .y-pagination_count span {
	font: 16px
}

.y-pagination .y-pagination_count a {
	margin-left: 5px
}

.y-pagination .y-pagination_count a.y-active_page_count,
.y-pagination .y-pagination_count a:hover {
	color: #212121
}

.y-side_filter_but {
	position: absolute;
	right: 0;
	top: 0;
	width: 64px;
	height: 48px;
	text-align: center;
	line-height: 48px;
	background: #fff;
	font-size: 20px;
	display: block;
	cursor: pointer
}

.y-product_filter_side {
	position: absolute;
	right: -340px;
	top: 0
}

.y-product_filter_side.y-side_filter_open {
	right: 65px
}

#y-price_range>div:first-child {
	display: none
}

.noUi-background {
	margin-top: 10px;
	background: #fff
}

.noUi-handle {
	background: #3aa6f5
}

.noUi-handle {
	box-shadow: none
}

.noUi-handle.noUi-handle-upper {
	top: -16px
}

.noUi-horizontal .noUi-handle {
	cursor: pointer
}

.y-product_filters .y-single_filter li span:hover {
	color: #3aa6f5
}

@media (max-width:1200px) {
	.y-product_listing_side>div {
		max-width: inherit
	}
	.y-product_listing>div a.y-heading {
		font-size: 20px;
		line-height: 24px
	}
	.y-product_listing>div a.y-heading br {
		display: none
	}
	.y-product_listing_full {
		margin: auto
	}
	.y-product_listing_full>div {
		max-width: 23.6%;
		margin: auto 15px 15px auto
	}
}

@media (max-width:991px) {
	.y-product_listing>div {
		margin: auto
	}
	.y-inner_container {
		max-width: calc(100% - 70px)
	}
	.y-pagination ul.y-pagination_num li {
		height: 42px;
		width: 42px
	}
	.y-pagination,
	.y-pagination .y-pagination_count {
		text-align: center
	}
	.y-pagination>div {
		margin: 5px 0
	}
	.y-product_listing>div a.y-heading {
		font-size: 16px;
		line-height: 20px
	}
	.y-product_listing_full>div {
		max-width: 100%;
		border: 8px solid #fafafa
	}
	.y-product_filter_side {
		display: none
	}
	.y-product_filter_side.y-side_filter_open {
		display: block
	}
}

@media (max-width:767px) {
	.y-product_listing>div {
		margin: auto auto 15px;
		border: none
	}
	.y-product_listing_full>div {
		max-width: inherit
	}
}

.y-breadcrum {
	margin: 0 auto 50px;
	position: relative;
	z-index: 99
}

.header-inner .header-cont {
	position: relative
}

.header-inner .header-cont::before {
	content: "";
	display: block;
	width: 136px;
	height: 166px;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
	opacity: .3
}

.y-breadcrum h1 {
	margin: auto
}

.y-breadcrum ul {
	list-style: none;
	background: #fff;
	padding: 0 0 30px;
	font-size: 14px
}

.y-breadcrum li {
	display: inline-block
}

.y-breadcrum a,
.y-breadcrum span {
	color: rgba(255, 255, 255, .5);
	font: 12px;
	text-transform: uppercase;
	letter-spacing: .5px;
	position: relative;
	padding: 0 10px
}

.y-breadcrum a:after {
	content: "";
	background-size: 100%;
	width: 10px;
	height: 10px;
	position: absolute;
	right: -6px;
	top: 3px
}

.y-breadcrum span {
	color: #212121
}

.y-cat_top_filter {
	margin-bottom: 50px
}

.y-cat_top_filter form {
	display: inline-block
}

.y-cat_top_filter ul {
	padding: 0;
	list-style: none;
	display: inline-block;
	margin-left: 35px
}

.y-cat_top_filter input {
	background: #eee none repeat scroll 0 0;
	border: medium none;
	border-radius: 12px 0 0 12px;
	color: #9e9e9e;
	display: inline-block;
	font: 16px;
	max-width: 185px;
	padding: 14px 20px;
	width: 100%
}

.y-cat_top_filter button {
	border-radius: 0 12px 12px 0;
	left: -5px;
	padding: 14px 18px;
	color: #424242;
	display: inline-block;
	position: relative;
	border: none;
	font-size: 16px;
	top: -.5px
}

.y-cat_top_filter li {
	padding: 14px 20px;
	margin-right: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	border-radius: 15px
}

.y-cat_top_filter li span {
	color: #424242;
	font: 16px;
	text-transform: uppercase
}

.y-cat_top_filter li i {
	font-size: 18px;
	margin-left: 18px;
	cursor: pointer
}

.y-cat_top_filter img {
	height: 48px
}

.y-product_images {
	background: #fff
}

.y-product_images #y-product_images>div.y-box:nth-child(1) {
	width: 100%
}

.y-product_images #y-product_images>div.y-box:nth-child(2) {
	width: 319px;
	float: left
}

.y-product_images #y-product_images>div.y-box:nth-child(3) {
	width: 168px;
	float: left
}

.y-product_text_details h5 {
	font-weight: 600;
	border-bottom: 3px solid;
	text-transform: uppercase;
	margin-top: 30px;
	color: #06062a
}

.y-product_text_details h4 {
	text-transform: capitalize
}

.y-product_text_details ul {
	padding: 0;
	list-style: none
}

.y-product_text_details li {
	padding-bottom: 5px;
	font-size: 14px;
	color: #616161;
	line-height: 28px
}

.y-product_text_details li strong {
	font-weight: 600;
	color: #212121
}

.y-product_text_details ul.y-product_attr {
	margin: 30px auto
}

.y-price_box span {
	color: #06062a;
	font-size: 26px;
	font-weight: 600;
	position: relative;
	top: 8px
}

.y-price_box a {
	padding: 10px 30px;
	text-transform: uppercase;
	margin-left: 20px;
	background: #3aa6f5;
	color: #fff
}

.y-relative_outer {
	padding-top: 100px;
	clear: both
}

.y-relative_outer h3 {
	color: #06062a
}

.y-relative_outer .owl-carousel .owl-item img {
	width: auto;
	margin: auto
}

div.container {
	position: relative
}

div.container .y-arrow_line {
	top: 35px;
	content: "";
	display: block;
	height: 2px;
	position: absolute;
	right: 30px;
	width: 74px;
	z-index: 999
}

div.container .y-arrow_line i {
	position: absolute;
	display: block;
	font-size: 24px;
	top: -12px;
	cursor: pointer
}

div.container .y-arrow_line i.y-left_anchor {
	left: -2px;
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg)
}

div.container .y-arrow_line i.y-right_anchor {
	right: -2px;
	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg)
}

div.container .y-arrow_line i:hover {
	color: #06062a
}

.y-relative_item {
	margin: 0
}

.y-relative_item>div {
	padding-top: 10px
}

.y-relative_item .y-relative_item>div>div.row {
	background: #fff
}

.y-relative_item .y-relative_img {
	min-height: inherit;
	max-width: 95%;
	margin: auto;
	position: relative;
	background: #fff;
	box-shadow: 0 0 5px #c7c6c6;
	-webkit-box-shadow: 0 0 5px #c7c6c6;
	-moz-box-shadow: 0 0 5px #c7c6c6
}

.y-relative_item .y-adv_info {
	position: relative;
	min-height: 165px;
	max-width: 95%;
	margin: auto;
	padding: 15px;
	border-top: none;
	box-shadow: 0 0 5px #c7c6c6 inset;
	-webkit-box-shadow: 0 0 5px #c7c6c6 inset;
	-moz-box-shadow: 0 0 5px #c7c6c6 inset
}

.y-relative_item img {
	position: static;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0
}

.y-relative_item a.y-heading {
	margin-bottom: 16px
}

.y-relative_item .y-adv_info_foot {
	position: absolute;
	width: calc(100% - 30px);
	bottom: 15px
}

.y-product_details {
	margin-top: 25px
}

.y-product_details ul {
	padding: 0;
	list-style: none
}

.y-product_details li {
	float: left;
	margin-right: 15px
}

.y-product_details li span {
	display: block;
	color: #9e9e9e;
	font-size: 12px;
	text-align: center
}

.y-product_details .y-nav_but {
	height: 46px;
	width: 50px;
	color: #212121;
	font-size: 16px;
	padding: 0;
	line-height: 46px
}

.y-product_details .y-attr_title {
	margin-bottom: 5px
}

.y-product_details .y-color_attr {
	background: #019bff
}

.y-product_details .y-price_box {
	margin-top: 15px
}

.y-product_details .y-price_box span {
	font-size: 34px;
	font-weight: 700;
	position: relative;
	top: 5px;
	display: inline-block;
	margin-right: 100px
}

.y-product_details .y-product_options {
	text-align: right;
	padding-top: 16px
}

.y-product_details .y-product_options ul {
	display: inline-block
}

.y-product_details .y-nav_but i {
	font-size: 18px
}

.owl-item {
	float: left
}

.owl-wrapper-outer {
	overflow: hidden
}

.owl-wrapper::after {
	clear: both;
	display: block;
	content: ""
}

.owl-carousel .owl-wrapper {
	display: none;
	position: relative;
	-webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0)
}

@media (max-width:1340px) {
	.y-product_images #y-product_images>div.y-box:nth-child(2) {
		width: 66%
	}
	.y-product_images #y-product_images>div.y-box:nth-child(3) {
		width: 34%
	}
	.y-product_details .y-price_box span {
		margin-right: 30px
	}
}

@media (max-width:989px) {
	.y-footer_strip,
	.y-popular_items .y-adv_info {
		max-width: 95%
	}
	div.container .y-arrow_line {
		bottom: 265px
	}
	div.container .y-arrow_line {
		width: 125px;
		right: 0
	}
}

@media (max-width:767px) {
	.y-product_details>div {
		text-align: center
	}
	.y-product_details>div ul {
		display: inline-block
	}
	.y-product_details .y-product_options {
		text-align: center
	}
}

.y-fun_facts {
	background-size: cover
}

.y-fun_facts h2 {
	color: #fff
}

.y-fun_facts_block {
	padding: 50px 0 0;
	overflow: hidden
}

.y-fact_single>div {
	height: 260px
}

.y-fact_single>div>i {
	height: 60px;
	width: 60px;
	text-align: center;
	color: #fff;
	display: block;
	margin: 50px auto 30px;
	line-height: 60px;
	font-style: normal;
	font-size: 40px
}

.y-fact_single>div>span.y-fact_count {
	color: #fff;
	font: 500 40px/1;
	display: block;
	text-align: center
}

.y-fact_single>div>span.y-fact_title {
	color: #fff;
	font: 200 14px/1;
	display: block;
	text-align: center;
	text-transform: uppercase;
	padding-top: 10px
}

.container .y-our_team h2 {
	color: #06062a
}

.container .y-our_team .y-arrow_line {
	top: -25px
}

.y-about_block {
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: center right
}

.y-our_team_list {
	margin-top: 50px;
	position: relative
}

.y-our_team_list .y-team_img {
	border: 1px solid #e5e5e5;
	padding: 8px;
	width: 100%;
	max-height: 100%;
	max-width: 100%;
	-webkiy-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease
}

.y-our_team_list .y-team_name {
	color: #000;
	font: 700 15px/1;
	text-transform: uppercase;
	display: block;
	letter-spacing: 2px;
	text-align: center
}

.y-our_team_list .y-team_name span {
	margin: 20px 0 10px;
	display: block
}

.y-our_team_list .y-team_post {
	color: #000;
	font: 400 11px/1;
	display: block;
	letter-spacing: 1px;
	text-align: center;
	font-style: italic
}

.y-slide_controls {
	text-align: center;
	padding: 80px 0 45px
}

.y-our_team_list .item {
	position: relative
}

.y-our_team_list .item:after {
	position: absolute;
	max-width: 254px;
	width: 30px;
	height: 1px;
	background: #cfcfcf;
	content: "";
	margin: auto;
	left: 0;
	right: 0;
	bottom: -20px;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease
}

.y-our_team_list .item:hover:after {
	width: 100%;
	background: #06062a
}

.y-our_team_list .item:hover img.y-team_img {
	border-color: #06062a
}

.y-our_team_list .item:hover .y-team_name {
	color: #06062a
}

.y-slide_controls a {
	display: inline-block;
	border: 1px solid #06062a;
	color: #06062a;
	width: 30px;
	height: 30px;
	margin: 0 3px;
	line-height: 28px
}

.y-slide_controls a:hover {
	background: #06062a;
	border: 1px solid #06062a;
	color: #fff
}

.y-our_team_carousel {
	overflow: hidden
}

.y-our_team_carousel .owl-nav {
	display: none
}

.y-our_team .y-arrow_line {
	padding: 45px 15px 15px;
	text-align: center
}

.y-our_team .y-arrow_line img {
	padding: 15px;
	cursor: pointer;
	min-width: 46px
}

.y-our_team_carousel .owl-wrapper-outer {
	min-height: 335px
}

.y-our_team_overlay {
	margin: auto;
	max-width: 100%;
	position: relative
}

.y-our_team_overlay li {
	display: inline-block;
	margin: 0 10px
}

.y-our_team_social {
	background: rgba(0, 0, 0, .3) none repeat scroll 0 0;
	bottom: 8px;
	height: calc(100% - 16px);
	left: 8px;
	max-height: 36px;
	max-width: 100%;
	opacity: 0;
	position: absolute;
	transition: all 1s ease 0s;
	width: 93%
}

.y-our_team_social>ul {
	bottom: 0;
	height: 100%;
	list-style: outside none none;
	margin: auto;
	max-height: 100px;
	padding: 0;
	position: absolute;
	text-align: center;
	top: 0;
	vertical-align: middle;
	width: 100%
}

.y-our_team_social a {
	color: #fff;
	font-size: 16px;
	margin: 8px 0
}

.y-our_team_social a:hover {
	color: #06062a
}

.y-our_team_list .item:hover .y-our_team_social {
	opacity: 1
}

@media (min-width:481px) and (max-width:767px) {
	.y-our_team_carousel .owl-wrapper-outer {
		min-height: 240px
	}
}

.y-corporate_block li {
	color: #121730
}

.y-map {
	height: 300px
}

.y-contact .y-contact_form {
	padding: 15px;
	background: #efefef;
	margin-top: 30px
}

.y-contact h3 {
	color: #06062a
}

.y-contact form.y-form button {
	color: #06062a
}

.y-contact form.y-form button:hover {
	color: #fff
}

.y-contact textarea {
	height: 100px
}

.y-contact address {
	color: #1c1c1c;
	line-height: 26px;
	font-size: 16px
}

.y-contact address span {
	font-weight: 600
}

.y-contact address a {
	color: #06062a
}

.y-contact abbr[title] {
	border: none
}

.y-contact ul li {
	color: #1c1c1c
}

.y-contact .y-contact_side>div {
	background-color: #efefef;
	padding: 25px 20px
}

.y-contact .y-contact_side>div {
	margin-bottom: 30px
}

.y-contact .y-contact_side>div:last-child {
	margin-bottom: 0
}

.y-contact .y-contact i {
	position: relative;
	top: 6px;
	margin-right: 3px
}

.y-contact_01 .y-contact i {
	position: relative;
	top: 6px;
	margin-right: 3px
}

.y-contact_01 .y-map {
	height: 400px
}

.y-contact_01 .y-contact .y-contact_side {
	background: 0 0
}

.y-contact_02 .y-contact i {
	position: relative;
	top: 6px;
	margin-right: 3px
}

.y-contact_02 .y-contact .y-contact_side>div {
	background: 0 0;
	padding: 0
}

.y-contact_02 .y-map {
	height: 200px;
	margin-bottom: 20px
}

.y-contact_02 .y-contact .y-social {
	margin-top: 30px
}

.y-contact_02 .y-contact .y-social a {
	display: block;
	height: 50px;
	margin-bottom: 10px;
	line-height: 30px;
	padding: 10px;
	text-align: center
}

.y-contact_02 .y-contact .y-social a,
.y-contact_02 .y-contact .y-social i {
	color: #fff
}

.y-contact_02 .y-contact .y-social i {
	font-size: 22px;
	top: 2px
}

.y-contact_02 .y-contact .y-social a:hover {
	opacity: .8
}

.y-footer>.container .row>div:nth-child(1) {
	background-size: 100%
}

.y-tw {
	background: #55acee
}

.y-gp {
	background: #dc4e41
}

.y-fb {
	background: #3b5998
}

.y-linkd {
	background: #0177b5
}

.header .y-tw:hover {
	background: #55acee
}

.header .y-gp:hover {
	background: #dc4e41
}

.header .y-fb:hover {
	background: #3b5998
}

.header .y-linkd:hover {
	background: #0177b5
}

.y-yt {
	background: #c00;
	display: none!important
}

.y-insta {
	background: #4b7699;
	display: none!important
}

.y-boxed {
	background-position: bottom
}

.y-boxed>section {
	background: #fff
}

.y-boxed>* {
	margin: auto;
	max-width: 1240px
}

.y-boxed .y-header_strip .header {
	max-width: 1240px
}

.y-boxed .parallax-image-wrapper {
	right: 0;
	margin: auto
}

.y-menu_outer {
	margin-top: 0;
	background: #06062a;
	position: relative;
	top: 15px;
	z-index: 9999;
	padding-left: 15px
}

.y-menu_outer .y-social {
	position: absolute;
	right: 20px;
	top: 10px
}

.rmm ul {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative
}

.rmm ul:after {
	content: "";
	clear: both;
	display: block
}

.rmm ul li {
	float: left
}

.rmm ul li:hover>ul {
	display: block
}

.rmm ul li:hover a {
	color: #fff
}

.rmm ul li a {
	color: #fff;
	display: block;
	text-decoration: none
}

.rmm ul ul {
	display: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 100%
}

.rmm ul ul li {
	float: none;
	position: relative
}

.rmm ul ul li a {
	color: #fff
}

.rmm ul ul li a:hover {
	background: #06062a;
	color: #fff!important
}

.rmm ul ul ul {
	position: absolute;
	left: 100%;
	top: 0;
	width: 100%
}

.rmm-toggled {
	min-height: 50px;
	margin: 0 auto;
	top: -34px;
	display: none;
	position: absolute;
	right: 0
}

.rmm-closed~.rmm-mobile {
	display: none!important
}

.rmm-toggled-controls {
	width: 100%
}

.rmm-toggled-title {
	width: 60%;
	float: left;
	display: none;
	font-size: 22px;
	color: #fff;
	font-weight: 600;
	padding: 8px 0 5px 10px;
	text-decoration: none;
	text-transform: uppercase;
	text-align: left;
	padding-left: 20px
}

.rmm-toggled-button {
	width: 20%;
	float: left;
	margin-top: 3px;
	display: block;
	width: 32px;
	padding: 0!important;
	margin: 13px 10px 0 0;
	float: right
}

.rmm-toggled-button span {
	float: left;
	display: block;
	margin: 3px 6px;
	height: 3px;
	background: #3aa6f5;
	width: 20px
}

.rmm .rmm-back {
	display: none;
	font-size: 12px
}

.rmm .rmm-back:after {
	display: none
}

.rmm .rmm-back a:after {
	content: "\f107";
	display: inline-block;
	float: left;
	font-family: fontawesome;
	font-size: 20px;
	position: relative;
	right: 2px;
	top: 0;
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg)
}

.rmm-mobile .rmm-back {
	display: block
}

.rmm-mobile {
	width: 100%
}

.rmm-mobile ul {
	width: 100%;
	position: inherit!important
}

.rmm-mobile li {
	width: 100%
}

.rmm-dropdown {
	display: block!important
}

.rmm-mobile .rmm-subview {
	display: block!important;
	width: 100%
}

.rmm-mobile .rmm-subover-hidden {
	display: none!important
}

.rmm-mobile .rmm-subover-visible {
	display: block
}

.rmm-mobile .rmm-subover-header {
	display: none!important;
	padding: 0;
	margin: 0
}

.rmm-mobile .rmm-submenu {
	position: relative;
	left: 0
}

.rmm .rmm-mobile li:hover>ul {
	display: none
}

.rmm.style {
	text-align: left;
	padding: 0 0 0
}

.rmm.style ul {
	display: inline-table;
	text-align: left
}

.rmm.style ul li {
	text-align: left
}

.rmm.style ul ul {
	display: none;
	margin: 0;
	min-width: 140px;
	background: #fff;
	border-radius: 0;
	padding: 0;
	position: absolute;
	z-index: 9999
}

.rmm.style ul li a {
	color: #fff;
	position: relative;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 12px 12px 8px;
	margin-right: 15px;
	display: block
}

.rmm.style ul ul li a {
	border-bottom: 1px solid #ccc;
	font-size: 13px;
	font-weight: 400;
	color: #06062a;
	text-transform: capitalize;
	padding: 6px 50px 6px 15px;
	margin-right: 0;
	min-width: 100px
}

.rmm.style ul li>a:not(:only-child):before {
	position: absolute;
	content: "\f107";
	right: 0;
	display: block;
	top: 15px;
	font-size: 12px
}

.rmm.style ul ul li>a:not(:only-child):after {
	-webkit-transform: rotate(120deg)!important;
	transform: rotate(120deg)!important;
	top: 2px;
	font-size: 14px;
	float: right;
	margin-right: -20px
}

.rmm.style .rmm-mobile li>a:not(:only-child):after {
	-webkit-transform: rotate(120deg)!important;
	transform: rotate(120deg)!important;
	top: 4px!important;
	font-size: 14px!important;
	float: right;
	margin-right: -20px
}

.rmm.style .rmm-mobile li {
	background: 0 0;
	border-bottom: 1px solid #b5bdc8!important;
	border-top: 0!important
}

.rmm.style ul.rmm-mobile li a {
	color: #06062a
}

.y-gray_bg {
	background: #e2e2e2 none repeat scroll 0 0
}

@media (max-width:1200px) {
	.y-our_services .y-servie_info h4 {
		font-size: 16px
	}
	.y-our_services .y-servie_info p {
		padding: 0 10px
	}
	.y-yacht_intro ul li:first-child {
		margin-right: 3px;
		padding-right: 5px
	}
	.y-yacht_intro_img {
		height: 210px
	}
	.responsive-tabs-wrapper .y-yacht_intro_img {
		height: auto
	}
	.y-back_to_top {
		right: -5px
	}
	.y-line {
		right: 20px
	}
	.y-blog_detail li::after {
		display: none
	}
	.rmm.style ul li a {
		padding: .96em .9em
	}
	.y-home_02 .y-header_form {
		top: 40px
	}
	.y-home_02 .y-our_services .y-servie_info span {
		font-size: 12px
	}
	.y-pagination {
		margin: auto
	}
	.header-home-2 .header-cont {
		z-index: 999;
		max-width: 85%;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width:1023px) {
	.y-landing>.y-inner_header {
		padding-bottom: 250px
	}
	.y-footer_strip,
	.y-popular_items .y-adv_info {
		max-width: 100%
	}
	.rmm.style .rmm-mobile ul li a {
		padding: .56em .86em
	}
	.rmm.style .rmm-mobile li ul li {
		border-bottom: none!important
	}
	.rmm.style ul.rmm-mobile ul {
		background: 0 0
	}
	.rmm.style .rmm-mobile ul li a {
		padding: 12px 22px 12px 22px;
		border-bottom: 1px solid #b5bdc8;
		color: #06062a
	}
	.rmm.style .rmm-mobile ul li:last-child a {
		border-bottom: none
	}
	.rmm.style ul.rmm-mobile li>a:not(:only-child)::before {
		transform: rotate(270deg);
		-webkit-transform: rotate(270deg);
		-moz-transform: rotate(270deg);
		right: 8px
	}
	.y-header_form form.y-form button {
		padding-top: 0
	}
	.y-form_gap {
		padding-right: 0
	}
	.y-our_services .y-servie_info h4 a {
		padding-left: 5px;
		padding-right: 5px
	}
	form.y-form button {
		font-size: 17px;
		padding: 6px 13px 13px
	}
	.y-header_outer .y-social {
		padding-top: 10px
	}
	.container .y-our_team .y-arrow_line {
		top: -100px;
		left: 0;
		margin: auto;
		right: 0;
		max-width: 260px;
		width: 100%
	}
	.bx-wrapper .bx-controls-auto,
	.bx-wrapper .bx-pager {
		bottom: 10px
	}
	.y-yacht_intro_img {
		height: auto
	}
	.y-yacht_intro_img img {
		position: static
	}
	.y-yacht_intro a {
		font-size: 14px
	}
	.y-yacht_intro ul li:first-child::after {
		border: none
	}
	.y-breadcrum {
		margin-bottom: 20px
	}
	.y-single_info_inner {
		padding-left: 0;
		padding-bottom: 0
	}
	.camera_next {
		background: rgba(255, 255, 255, .7) none repeat scroll 0 0
	}
	.camera_commands,
	.camera_next,
	.camera_prev {
		bottom: 0;
		text-align: center
	}
	.camera_prev {
		left: 0;
		background: rgba(255, 255, 255, .7) none repeat scroll 0 0
	}
	.y-relative_item.owl-carousel .owl-item .col-lg-12 {
		max-width: 266px;
		margin: auto;
		float: none
	}
	.y-relative_item .y-relative_img {
		padding: 10px;
		max-width: 100%
	}
	div.container .y-arrow_line i.y-left_anchor {
		left: 30px
	}
	div.container .y-arrow_line i.y-right_anchor {
		right: 30px
	}
	.responsive-tabs__list__item {
		padding: 20px 15px;
		font-size: 14px
	}
	.responsive-tabs {
		padding-left: 0;
		padding-right: 0
	}
	.y-inner_page .y-sidebar {
		margin-right: -15px
	}
	.y-single_info_inner .col-sm-8 {
		padding: 0
	}
	.y-contact {
		margin-bottom: 25px
	}
	.y-single_info_inner .y-contact .col-sm-8 {
		padding: 0 15px
	}
	.y-contact address,
	.y-contact ul li {
		font-size: 14px
	}
	.y-header_outer .y-social {
		top: -58px;
		left: 0;
		right: auto
	}
	.rmm-toggled {
		top: -62px
	}
	.rmm-toggled-button {
		margin-right: 0
	}
	.y-header_01 .rmm.style ul.rmm-mobile {
		margin-top: 0
	}
	.y-header_top_info .col-xs-12 {
		padding-bottom: 15px
	}
	.y-home_02 .y-header_form,
	.y-home_02 .y-slider_form>div#y-header_form {
		position: static;
		max-width: 100%
	}
	.y-breadcrum {
		margin-bottom: 0
	}
	.skrollr-desktop body {
		height: auto!important
	}
}

@media (min-width:768px) and (max-width:1023px) {
	.y-our_services .y-servie_info p {
		font-size: 12px;
		line-height: 16px;
		margin-top: 10px
	}
	.y-our_services .y-servie_info h4 {
		font-size: 16px;
		font-size: 11px
	}
	.y-our_services .y-servie_info h4 a {
		padding: 13px 8px
	}
	.y-footer .col-lg-3 {
		min-height: 200px
	}
	.y-header_strip .y-header_form .y-form>div.col-lg-4>div.y-form_gap {
		padding-left: 0
	}
	.responsive-tabs__list__item {
		padding-left: 10px;
		padding-right: 10px
	}
	.y-inner_page .y-sidebar {
		margin-bottom: 30px
	}
	.y-menu_outer .y-social {
		display: none
	}
}

@media (max-width:767px) {
	.y-menu_outer {
		background: 0 0
	}
	.y-menu_outer .y-social {
		display: none
	}
	.y-landing>.y-inner_header {
		padding-bottom: 300px;
		background-size: cover
	}
	.y-landing .y-landing_list img {
		margin: 10px auto auto
	}
	.y-header_strip .y-header_form .y-form>div.col-lg-4>div {
		padding-right: 0
	}
	#skrollr-body {
		height: auto
	}
	.header .container>div:nth-child(2)>div {
		padding-bottom: 30px
	}
	.y-header_outer .y-social {
		position: absolute;
		top: -75px;
		right: 15px
	}
	.y-logo img {
		max-height: 78px
	}
	form.y-form button {
		font-size: 17px;
		padding: 6px 13px 8px
	}
	.y-header_strip #y-header_form {
		margin-top: 150px
	}
	.y-line {
		opacity: 0
	}
	.y-back_to_top {
		opacity: 0
	}
	.y-dest_list_single .col-sm-7 {
		clear: both;
		padding-top: 1px
	}
	.y-dest_list_single img {
		border: none
	}
	.y-header_outer address a {
		font-size: 14px
	}
	.y-home_02 .y-header_form,
	.y-home_02 .y-slider_form>div {
		position: static
	}
	.y-home_02 .y-header_form {
		padding-left: 0;
		padding-right: 0
	}
	.y-lang_icons,
	.y-login_but {
		text-align: center
	}
	.y-single_info_inner {
		padding-left: 0;
		padding-right: 0
	}
	.y-breadcrum {
		margin: auto
	}
	.y-breadcrum ul {
		float: none!important
	}
	.y-product_listing>div {
		border: medium none;
		float: none;
		margin: auto auto 15px;
		max-width: 354px
	}
	.y-contact_side {
		float: none!important
	}
	.y-pagination {
		margin: auto
	}
	.y-product_listing>div {
		max-width: 300px
	}
	.y-single_info_inner .col-sm-12 .col-sm-3.pull-right {
		float: none!important
	}
	.y-relative_item img {
		position: static
	}
	div.container .y-arrow_line {
		display: none
	}
	div.container .y-our_team_list .y-arrow_line {
		display: block
	}
	.y-product_text_details {
		margin-top: 25px
	}
	.y-single_info_inner .y-dest_list h3 {
		margin-top: 20px
	}
	.y-single_info_inner li {
		line-height: 22px
	}
	.responsive-tabs__heading--active {
		background: #06062a
	}
	.y-inner_page .y-sidebar {
		margin: 0 -15px 20px
	}
	.y-breadcrum ul li:first-child a {
		padding-left: 0
	}
	.y-blog_general_info li i {
		top: 4px
	}
	.y-single_info_inner .y-contact .col-sm-8 {
		padding: 0
	}
	.y-contact .y-contact_side {
		clear: both
	}
	.y-contact_inner>address:last-child {
		margin-bottom: 0
	}
	.y-contact_inner {
		margin-bottom: 15px
	}
	.y-header_outer .y-social {
		display: none
	}
	.rmm.style {
		padding-top: 0
	}
	.y-dest_list_single .pull-right.col-lg-5 img {
		float: none
	}
	.y-blog_info {
		margin-top: 15px
	}
	.y-fact_single>div {
		height: auto
	}
	.y-relative_item {
		padding-top: 30px
	}
	.y-relative_outer div.container .y-arrow_line {
		display: block;
		top: 70px
	}
	.responsive-tabs .y-client_testimonials {
		padding: 15px 0
	}
	.responsive-tabs .y-client_testimonials p {
		text-align: justify
	}
	.responsive-tabs .y-client_testimonials img {
		position: static
	}
	.y-blog_sidebar {
		float: none!important
	}
	.y-blog_sidebar .y-sidebar {
		max-width: 400px;
		margin: auto auto 20px
	}
	.y-landing .y-single_info_inner {
		margin: auto
	}
	.y-client_says_section {
		background-attachment: scroll
	}
}

.header-inner.hi-single-yacht {
	padding-top: 0;
	background: #fff
}

.single-yacht .owl-nav {
	position: absolute;
	bottom: 60px;
	left: 0;
	width: 100%;
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	right: 0;
}

.owl-carousel.single-yacht .owl-nav button.owl-next,
.owl-carousel.single-yacht .owl-nav button.owl-prev {
	border-radius: 0
}

.owl-carousel.single-yacht .owl-dots {
	display: none
}

.owl-carousel.single-yacht .owl-stage-outer {
	padding-bottom: 0
}

.single-yacht::after {
	content: "";
	position: absolute;
	top: 0;
	width: 100%;
	height: 160px;
	background-color: transparent;
	background-image: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, 0))
}

.single-yacht .item img {
	max-height: 100vh;
	object-fit: cover
}

.hi-single-yacht .header-cont {
	padding: 120px 20px 30px;
	position: absolute;
	right: 0;
	top: 0;
	width: 300px;
	left: auto;
	height: 100%;
	background: rgba(0, 12, 30, .95);
	overflow-y: auto
}

.hi-single-yacht .header-cont ul {
	padding-left: 0;
	padding-bottom: 30px
}

.hi-single-yacht .header-cont ul li {
	padding-top: 4px;
	padding-bottom: 4px;
	list-style: none
}

.hi-single-yacht .header-cont ul li a {
	color: rgba(255, 255, 255, .9)
}

.hi-single-yacht .header-cont ul li a:hover {
	color: #6380aa
}

.hi-single-yacht .header-cont:before {
	display: none
}

.hi-single-yacht .header-cont .single-yacht_dtails h1 {
	font-size: 25px;
	text-align: right;
	text-transform: uppercase;
	font-weight: 700
}

.single-yacht_dtails {
	text-align: right
}

.single-yacht_dtails>div {
	margin: 15px auto 30px
}

.single-yacht_dtails p {
	margin-bottom: 0;
	color: #6380aa
}

.hi-single-yacht .single-yacht_dtails span {
	padding-left: 0;
	padding-right: 0
}

.single-yacht_dtails p .value {
	color: #fff
}

.single-yacht_number {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between
}

.single-yacht_number li {
	list-style: none;
	display: inline-block;
	color: #fff;
	width: auto;
	text-align: center
}

.single-yacht_number li span {
	display: block;
	text-align: center;
	font-size: 36px
}

.hi-single-yacht .single-yacht_number li span {
	color: #6380aa;
	padding: 0
}

.single-yacht_dtails .btn.btn-primary-ouline {
	border: 1px solid;
	border-radius: 100px;
	padding: 10px 26px;
	width: 100%;
	text-transform: uppercase
}

.single_side_action {
	padding-top: 20px
}

.single_side_action li {
	display: inline-block;
	vertical-align: middle;
	width: 23%;
	text-align: center
}

.y-inner_page .card .card-header {
	padding: 0;
	border: none;
	border-top: 1px solid rgba(0, 0, 0, .3);
	background: 0 0
}

.y-inner_page .card .card-header a {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 15px;
	color: #121730
}

.card .card-header h2 {
	margin: 0;
	font-size: 30px;
	font-weight: 700;
	color: #000;
	padding-left: 15px
}

.card .card-header h2 .ti-anchor {
	margin-right: 30px;
	display: inline-block;
	vertical-align: middle
}

.card {
	border: none
}

.y-single_details .card-body {
	padding-left: 102px
}

.card_view {
	font-size: 0
}

.card_view:after {
	content: "Vidi manje -";
	font-size: 15px
}

.card-link.collapsed .card_view:after {
	content: "Vidi više +"
}

.box_line {
	padding: 70px 50px;
	position: relative;
	min-width: 25vw;
	text-align: left
}

.box_line_row>div:last-child .box_line:after,
.box_line_row>div:last-child .box_line:before {
	display: none
}

.box_line::before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	background: #ddc29a;
	position: absolute;
	right: -15px;
	top: 0
}

.box_line::after {
	content: "";
	display: block;
	width: 40px;
	height: 80px;
	background-color: #fff;
	position: absolute;
	right: -35px;
	top: 0;
	bottom: 0;
	margin: auto;
	background-size: auto 40px;
	background-repeat: no-repeat;
	background-position: center center
}

.speci_ul {
	display: flex;
	flex-wrap: wrap;
	padding: 0
}

.speci_ul li {
	width: 24%;
	margin-right: 0;
	list-style: none;
	padding: 15px;
	margin-right: 1%;
	background-color: rgba(0, 0, 0, .1)
}

.y-single_form {
	background-size: cover;
	background-color: rgb(0, 12, 30, .8);
	background-blend-mode: multiply;
	padding-top: 80px;
	padding-bottom: 80px;
	background-position: center bottom
}

.y-single_form form {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10px
}

.y-single_form .thm-h {
	color: #fff
}

.y-single_form .form-control {
	background: 0 0;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #6a88b5;
	color: #6a88b5
}

.y-single_form textarea.form-control {
	border: 1px solid #6a88b5
}

.y-single_form label {
	color: #6a88b5;
	padding-left: .75rem
}

.y-single_form .form-control:focus {
	box-shadow: none;
	border-color: #fff;
	color: #fff
}

.y-single_form .form-group {
	margin-bottom: 45px
}

.y-single_form .btn.btn-primary {
	border: 1px solid;
	color: #6a88b5;
	border-radius: 100px;
	padding: 10px 26px;
	width: 100%;
	text-transform: uppercase;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	background: 0 0
}

.y-single_form .btn.btn-primary:hover {
	color: #fff
}

@media screen and (max-width:1199px) {
	.menu-1-inner .dropdown .btn {
		padding-left: 3px;
		padding-right: 3px
	}
	.booknow_box {}
	.h3-book-section .booknow_box h4 {
		width: 100%;
		text-align: center;
		margin-bottom: 30px
	}
	.single-yacht .item img {
		height: 100vh;
		object-fit: cover
	}
}

@media screen and (max-width:1099px) {
	/*.menu-1-inner>div {
		display: none
	}*/
	.menu-1-inner .menu-1-logo {
		display: block;
		padding-left: 10px
	}
	/*.menu-1-inner .nav-search {
		display: block;
		margin-right: auto
	}*/
	.header-home-1>video {
		width: auto;
		height: 100%
	}
	/*.menu-1-inner>div.nav-search {
		margin-right: 0;
		margin-left: auto
	}*/
	/*.menu-1-inner .menu-1-logo,
	.menu-1-inner>div.nav-search {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%
	}*/
	.nav-search .nav-search-form.search-open {
		width: 300px
	}
	.h3-book-section .booknow_box .btn-primary,
	.h3-book-section .booknow_box .form-group {
		margin-top: 15px;
		margin-bottom: 15px
	}
}

@media screen and (max-width:991px) {
	.footer-3.footerdark {
		background-size: cover
	}
	.charter-carousel-2 .owl-nav {
		width: 100%;
		text-align: center
	}
	.charter-carousel-2 .owl-dots {
		width: 100%;
		text-align: center
	}
	.main_nav_outer {
		display: none;
	}
}

@media screen and (max-width:767px) {
	.menu-1 .nb-dark,
	.menu-1 .nb-light {
		max-width: 30px
	}
	.navbar-brand img {
		max-height: 30px
	}
	.menu-1.menu-1-scrolled>div,
	.menu-2.menu-2-scrolled>div {
		width: 100%
	}
	.menu-1-inner .menu-1-logo {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%
	}
	.menu-1-inner>div.nav-search {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%
	}
	.hamburger {
		padding: 5px
	}
	.navbar-toggler {
		padding: 0
	}
	.nav-search .login_but {
		padding: 5px 15px
	}
	.nav-search .searchclick {
		padding: 0 15px 0 0
	}
	.cart_index {
		right: -86px;
		left: auto
	}
	.nav-search .nav-search-form.search-open {
		width: 300px;
		right: 10px;
		left: auto
	}
	.menu-1-inner .btn-outline-success {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.menu-1-inner a.navbar-brand {
		padding-top: 15px;
		padding-bottom: 15px
	}
	.hamburger-inner,
	.hamburger-inner::after,
	.hamburger-inner::before {
		width: 32px
	}
	.hamburger-box {
		width: 32px
	}
	.header-home-1 .header-cont {
		padding-left: 15px;
		padding-right: 15px
	}
	.header-home-1 h1 {
		font-size: 7vw
	}
	.header-home-1 h5 {
		font-size: 14px;
		letter-spacing: .1em
	}
	.servicebox-first {
		height: auto;
		margin-bottom: 30px
	}
	.servicemore {
		position: static;
		opacity: 1;
		visibility: visible;
		margin-top: 15px;
		display: inline-block
	}
	footer .footer-box {
		margin-bottom: 35px
	}
	.header-home-2 .header-cont {
		max-width: 100%;
		margin-left: 0;
		margin-right: auto
	}
	.header-home-2 {
		padding-left: 15px;
		padding-right: 15px
	}
	.header-home-2.header-scrolled,
	.header-home-4.header-scrolled {
		margin: 0
	}
	.h2-book-section::after {
		display: none
	}
	.h2-book-section {
		padding-left: 0;
		height: auto
	}
	.h2-book-section .home-about {
		padding: 15px 30px 0;
		background: #fff
	}
	.h2-book-section .booknow_box {
		margin-top: 10px
	}
	.thm-h-3.thm-h h2 {
		font-size: 47px
	}
	.hh3 .header-cont h1 {
		font-size: 8vw
	}
	.h3-book-section .booknow_box .btn-primary,
	.h3-book-section .booknow_box .form-group {
		width: 100%;
		margin-left: 0;
		margin-right: 0
	}
	.h3-book-section .booknow_box .form-control {
		margin-left: 0;
		margin-right: 0
	}
	.count_single::after,
	.count_single::before {
		display: none
	}
	.count_single {
		padding: 20px;
		position: relative
	}
	.hi-single-yacht .header-cont {
		padding: 60px 20px 30px;
		width: 100%;
		left: auto;
		height: auto;
		position: static
	}
	.hi-single-yacht .header-cont ul,
	.single-yacht_number {
		max-width: 350px;
		margin-left: auto;
		margin-right: auto
	}
	.hi-single-yacht .header-cont .single-yacht_dtails h1,
	.single-yacht_dtails {
		text-align: center
	}
	.single-yacht_dtails>div:last-child {
		margin-top: 0
	}
	.single-yacht_dtails .btn.btn-primary-ouline {
		max-width: 170px
	}
	.y-single_details .card-body {
		padding: 0
	}
	.y-inner_page .card .card-header a {
		padding: 20px 0 20px 30px;
		color: #121730
	}
	.card .card-header h2 {
		font-size: 18px;
		padding-left: 0
	}
	.card .card-header h2 .ti-anchor {
		margin-right: 0;
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		top: 25px;
		left: 0
	}
	.charter-carousel-2 .owl-dots .owl-dot::before {
		width: 10px
	}
	.charter-carousel-2 .owl-dots .owl-dot.active::before {
		width: 30px;
		transition: all .4s
	}
	.charter-carousel-2 .owl-dot::after {
		font-size: 12px
	}
	.single-yacht .item img {
		height: auto;
		object-fit: cover
	}
	.single-yacht .owl-nav {
		position: absolute;
		bottom: -53px;
		left: 0;
		right: 0;
		text-align: center
	}
	.card-link.collapsed .card_view::after {
		content: "+"
	}
	.card_view::after {
		content: "-"
	}
	.charter-carousel.owl-carousel .owl-nav {
		top: auto
	}
	.header-inner .y-breadcrum h1 {
		font-size: 25px
	}
	.header-inner {
		padding-top: 10rem
	}
	.y-about_block {
		background-position: center center
	}
	.speci_ul li {
		width: 48%;
		margin-right: 0;
		list-style: none;
		padding: 15px;
		margin-right: 2%;
		background-color: rgba(0, 0, 0, .1);
	}
	.box_line {
		padding: 30px 15px
	}
	.box_line::before {
		width: 100%;
		height: 1px;
		top: 100%;
		left: 0;
		right: 0;
	}
	.box_line::after {
		width: 30px;
		height: 60px;
		right: 0;
		top: auto;
		bottom: -32px;
		margin: auto;
		background-size: auto 30px;
		left: 0;
	}
	.header-home-2, .h2-book-section  {
		background-attachment:scroll;
	}
	.h2-book-section .home-about {
		margin: 0 auto 0;
	}
}



